import pinkBot from '../images/pink-bot.png'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import React, { useState, useEffect, useRef } from 'react';
import { FormGroup, Label, Table, Container, Row, Col, Card, CardDeck, CardBody, Nav, NavItem, NavLink, Navbar, Input, NavbarBrand, Button } from 'reactstrap';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { AiOutlineCloudDownload } from 'react-icons/ai';
import { IoMdRemoveCircle } from 'react-icons/io';
import ReactIosSwitch from 'react-ios-switch';
import '../App.css';
import { AiOutlineAppstoreAdd } from 'react-icons/ai';
import { db } from '../firebase'; // Path to your firebase.js file
import { collection, onSnapshot, getDocs, query, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import axios from 'axios';
import { InfinitySpin } from "react-loader-spinner";
import moment from 'moment';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";





function toSentenceCase(text) {
    return text.toLowerCase().replace(/^(.)|\s(.)/g, function ($1) {
        return $1.toUpperCase();
    });
}

const data = [
    { name: 'Jan', value: 12048 },
    { name: 'Feb', value: 15049 },
    { name: 'Mar', value: 10293 },
    { name: 'Apr', value: 18202 },
    { name: 'May', value: 19204 },
    { name: 'Jun', value: 17012 },
    { name: 'Jul', value: 21938 },
    { name: 'Aug', value: 20139 },
];
const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <h4 className="bold-text text-black label">{`$${payload[0].value}`}</h4>
            </div>
        );
    }
    return null;
};

const MyLineChart = () => (
    <ResponsiveContainer width="100%" height={300}>
        <LineChart
            data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
            <CartesianGrid strokeDasharray="2 2" />
            <XAxis dataKey="name" />
            <Tooltip content={<CustomTooltip />} />
            <Line type="monotone" dataKey="value" stroke="#8884d8" strokeWidth={3} activeDot={{ r: 8 }} />
        </LineChart>
    </ResponsiveContainer>
);

const TipsHelper = () => {
    const tableRefs = useRef({});
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [loadingText, setLoadingText] = useState('Loading...');
    const [unsuccessfulData, setUnsuccessfulData] = useState([]);
    const loadingMessages = [
        "Loading...",
        "Scanning Square transactions...",
        "Comparing transactions to Dr Chrono appointments...",
        "Adding up tips for nurses...",
        "Wrapping up...."
    ];

    useEffect(() => {
        if (loading) {
            let loadingIndex = 0;
            const interval = setInterval(() => {
                loadingIndex++;
                if (loadingIndex >= loadingMessages.length) {
                    clearInterval(interval);
                } else {
                    setLoadingText(loadingMessages[loadingIndex]);
                }
            }, 3000);

            return () => clearInterval(interval);
        }
    }, [loading]);

    const printDocument = (roomName) => {
        const input = tableRefs.current[roomName];
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('l', 'mm', 'a4');  // 'l' for landscape
                pdf.addImage(imgData, 'JPEG', 0, 0);
                pdf.save(`${roomName}_download.pdf`);
            });
    };

    // New function for adding a row
    const addRow = (roomName) => {
        const newRow = {
            patient_id: '',
            name: '',
            reason: '',
            scheduled_time: '',
            tip: '',
            order: ''
        };

        setData((prevData) => {
            return {
                ...prevData,
                [roomName]: {
                    ...prevData[roomName],
                    patients: [...prevData[roomName].patients, newRow]
                }
            };
        });
    };

    // New function for removing a row
    const removeRow = (roomName, index) => {
        setData((prevData) => {
            const updatedPatients = prevData[roomName].patients.filter((_, i) => i !== index);
            const updatedTips = updatedPatients.reduce((total, patient) => total + patient.tip, 0);

            return {
                ...prevData,
                [roomName]: {
                    patients: updatedPatients,
                    totalTips: updatedTips
                }
            };
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
        const formattedEndDate = moment(endDate).format("YYYY-MM-DD");
        try {
            const response = await axios.get(
                `https://imac-med-spa.labthree.ai/job-85?startTime=${formattedStartDate}&endTime=${formattedEndDate}`
                // `http://localhost:3000/job-85?startTime=${formattedStartDate}&endTime=${formattedEndDate}`
            );
            const rawData = response.data.pairResult.successful;
            setUnsuccessfulData(response.data.pairResult.unsuccessful);
            console.log(response.data)
            const uniqueData = rawData.reduce((acc, current) => {
                const x = acc.find(item =>
                    (item.matchedAppointments && Array.isArray(item.matchedAppointments)) &&
                    item.matchedAppointments.some(appointment =>
                    (appointment.name === current.name && appointment.reason === current.reason &&
                        appointment.scheduled_time === current.scheduled_time && item.result.tip === current.result.tip)
                    ));

                if (!x) {
                    return acc.concat([current]);
                } else {
                    return acc;
                }
            }, []);

            const processedData = uniqueData.reduce((rooms, item) => {
                if (item.matchedAppointments && Array.isArray(item.matchedAppointments)) {
                    item.matchedAppointments.forEach((appointment) => {
                        const roomName = appointment.exam_room;
                        if (!rooms[roomName]) {
                            rooms[roomName] = {
                                patients: [],
                                totalTips: 0,
                            };
                        }
                        rooms[roomName].patients.push({
                            patient_id: appointment.patient.id,
                            name: appointment.patient.name,
                            reason: appointment.reason,
                            scheduled_time: appointment.scheduled_time,
                            tip: item.result.tip,
                            order: item.result.order_id
                        });
                        rooms[roomName].totalTips += item.result.tip;
                    });
                }
                return rooms;
            }, {});
            console.log(processedData)

            setData(processedData);
        } catch (error) {
            console.error("There was an error with the request", error);
        } finally {
            setLoading(false);
        }
    };

    // New function to handle tip changes
    const handleTipChange = (roomName, index, newTip) => {
        setData((prevData) => {
            const updatedPatients = [...prevData[roomName].patients];
            updatedPatients[index].tip = newTip * 100; // Converting back to cents

            const updatedTips = updatedPatients.reduce((total, patient) => total + patient.tip, 0);

            return {
                ...prevData,
                [roomName]: {
                    patients: updatedPatients,
                    totalTips: updatedTips
                }
            };
        });
    };

    return (
        <>
            <Card body className="rounded-card">

                <h5 className="bold-text text-black">Tips Helper</h5>

                <p>Helper to calculate tips for nurses based on transactions pulled from Square.</p>
                <FormGroup>
                    <Label for="startDate">Start Date</Label>
                    <Input type="date" id="startDate" onChange={(e) => setStartDate(e.target.value)} />
                </FormGroup>
                <FormGroup>
                    <Label for="endDate">End Date</Label>
                    <Input type="date" id="endDate" onChange={(e) => setEndDate(e.target.value)} />
                </FormGroup>
                <Button className="rounded-card" onClick={handleSubmit} disabled={loading}>
                    {loading ? loadingText : 'Generate Report'}
                </Button>
                <br />
                {Object.entries(data).map(([roomName, roomData]) => (
                    <div key={roomName} ref={(el) => tableRefs.current[roomName] = el}>
                        <Card body className="my-2 rounded-card" style={{ backgroundColor: '#fff4ee' }} >
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <h4 className="text-black bold-text">{roomName}</h4>
                                <span type="button" className="rounded-card" onClick={() => printDocument(roomName)}>
                                    <span style={{ fontSize: '1.2rem' }}>
                                        <AiOutlineCloudDownload />
                                    </span>
                                </span>
                            </div>
                            <span>{moment(startDate).format('MM/DD/YY')} to {moment(endDate).format('MM/DD/YY')}</span>
                            <br />

                            <table border="true" style={{ borderColor: '#d3d3d3', width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th>Patient</th>
                                        <th>Procedure</th>
                                        <th>Time</th>
                                        <th>Tip</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {roomData.patients.map((patient, index) => (
                                        <tr key={index}>
                                            {
                                                patient.name ?
                                                    <>
                                                        <td className="text-black bold-text">
                                                            <small><a target="_blank" href={`https://macmedspa.drchrono.com/misc/patient_appointments/${patient.patient_id}`}>{toSentenceCase(patient.name)}</a></small>
                                                        </td>
                                                    </> :
                                                    <>
                                                        <td className="text-black bold-text" contentEditable>
                                                            <small><a target="_blank" href={`https://macmedspa.drchrono.com/misc/patient_appointments/${patient.patient_id}`}>{toSentenceCase(patient.name)}</a></small>
                                                        </td>
                                                    </>
                                            }

                                            <td className="text-black" contentEditable><small>{patient.reason}</small></td>
                                            <td className="text-black" contentEditable>
                                                <small>{patient.scheduled_time && moment(patient.scheduled_time).format('MMMM Do YYYY, h:mm:ss a')}</small>
                                            </td>
                                            {
                                                patient.tip > 0 ?
                                                    <>
                                                        <td className="text-black bold-text"><a target="_blank" href={`https://squareup.com/dashboard/sales/transactions/${patient.order}`}>${Number(patient.tip / 100).toFixed(2)}</a></td>
                                                    </> :
                                                    <>
                                                        <td className="text-black bold-text" contentEditable
                                                            onBlur={(e) => handleTipChange(roomName, index, parseFloat(e.target.textContent.replace('$', '')))}>
                                                            <a target="_blank" href={`https://squareup.com/dashboard/sales/transactions/${patient.order}`}>
                                                                ${Number(patient.tip / 100).toFixed(2)}
                                                            </a>
                                                        </td>

                                                    </>
                                            }

                                            <td className="text-center" style={{ fontSize: '1.2rem' }}>
                                                <IoMdRemoveCircle onClick={() => removeRow(roomName, index)} />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <br />
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div>
                                    <AiOutlineAppstoreAdd onClick={() => addRow(roomName)} />
                                </div>
                                <span>Total tips<br /> <span className="font-weight-bold">${Number(roomData.totalTips / 100).toFixed(2)}</span></span>
                            </div>
                        </Card>
                    </div>
                ))}
                {unsuccessfulData.length > 0 &&
                    <Card body className="rounded-card">
                        <h5 className="bold-text text-black">Unsuccessful Data</h5>
                        <table border="true" style={{ borderColor: '#d3d3d3', width: '100%' }}>
                            <thead>
                                <tr>
                                    <th>Customer Name</th>
                                    <th>Date</th>
                                    <th>Tip</th>
                                    <th>Transaction</th>
                                </tr>
                            </thead>
                            <tbody>
                                {unsuccessfulData.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.customerName ? <>{item.customerName}</> : <>No info</>}</td>
                                        <td><a target="_blank" href={`https://macmedspa.drchrono.com/appointment-dashboard#/scheduled_date=${moment(item.createdAt).format('YYYY-MM-DD')}&office_id=268462&view=information`}>{moment(item.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</a></td>
                                        <td><a target="_blank" href={`https://squareup.com/dashboard/sales/transactions/${item.order_id}`}>View in Square</a></td>
                                        <td>${Number(item.tip / 100)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Card>
                }
            </Card>
        </>
    );
};


function SimpleCard({ id, running, title, description, children }) {
    const [expanded, setExpanded] = useState(false); // State to toggle the description
    const [jobActive, setJobActive] = useState(true);
    const jobRef = doc(db, 'Jobs', 'Receptionist');

    useEffect(() => {
        const fetchData = async () => {
            const docSnap = await getDoc(jobRef);
            if (docSnap.exists()) {
                setJobActive(docSnap.data()[`job_${id}`]);
            }
        };
        fetchData();
    }, []);


    const toggleJob = async () => {
        await updateDoc(jobRef, { [`job_${id}`]: !jobActive });
        setJobActive(!jobActive);
    };


    return (
        <>
            <Card body className="rounded-card">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        <div style={{ left: '10px', display: 'flex', alignItems: 'center' }}>
                            {
                                jobActive ?
                                    <>
                                        <div style={{ width: '10px', height: '10px', backgroundColor: '#90EE90', borderRadius: '50%', animation: 'pulsate 2s infinite' }}></div>
                                        <span style={{ fontSize: '15px', marginLeft: '5px' }}>{running}</span>
                                    </> :
                                    <>
                                        <div style={{ width: '10px', height: '10px', backgroundColor: 'grey', borderRadius: '50%', animation: 'pulsate 2s infinite' }}></div>
                                        <span style={{ fontSize: '15px', marginLeft: '5px' }}>Inactive</span>
                                    </>
                            }
                        </div>
                        <h5 type="button" onClick={() => { setExpanded(!expanded) }} className="bold-text text-black">{title}</h5>
                        <p className="">{description}</p>
                    </div>
                    {expanded &&
                        <div className="pt-2 pr-2" style={{ position: 'absolute', top: '0', right: '0' }}>
                            <ReactIosSwitch
                                checked={jobActive}
                                onChange={toggleJob}
                            />
                        </div>
                    }
                </div>
                <div>
                    {expanded && children}
                </div>
            </Card >
        </>
    )
}

function Sales() {

    return (
        <div className="App">
            <Container className="text-left">
                <br />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={pinkBot} alt="Pink Bot" style={{ height: '10em', marginRight: '5px' }} />
                    <div>
                        <h2 className="bold-text text-black">Sales Bot</h2>
                        <p className=" text-black rounded-card" style={{ backgroundColor: '#fff' }}>I'm your AI-powered bot, specialized in boosting sales, growing leads, outreach, and monitoring revenue stats to propel your business forward!</p>
                    </div>
                </div>
                <br />
                <h3 className="bold-text text-black text-left">Staff</h3>
                <TipsHelper />
                <h3 className="bold-text text-black text-left">Jobs</h3>
                <SimpleCard id="10" running="Running 24/7" title="Keep track of tips for my nurses" description="Each time a payment is completed in Square, I'll find out which appointment it was for and keep track of it.">

                </SimpleCard>
                {/* <h3 className="bold-text text-black">Daily Report</h3>
                <CardDeck>
                    <Card className="rounded-card  text-center">
                        <small className="">
                            New Leads (this week)
                        </small>
                        <h3>8</h3>
                    </Card>
                    <Card className="rounded-card  text-center">
                        <small className="">
                            Conversions (this week)
                        </small>
                        <h3>3</h3>
                    </Card>
                    <Card className="rounded-card  text-center">
                        <small className="">
                            Total Sales (this week)
                        </small>
                        <h3>$13,019</h3>
                    </Card>
                   
                    <Card className="rounded-card  text-center">
                        <small className="">
                            Ad Spend (this week)
                        </small>
                        <h3 className="">$759</h3>
                    </Card>
                </CardDeck>
                <br />
                <MyLineChart />
                <br />
                <h3 className="bold-text text-black">Jobs</h3>
                <CardDeck>
                <SimpleCard running="Working 24/7" title="Reaching out to new leads via Email" description="I'll send customized emails to new leads based on what they are inquiring about." />
                <SimpleCard running="Working 24/7" title="Having real SMS conversations with new leads" description="After emails, I'll have actual conversations with real leads to get more information and help them convert." />
                </CardDeck>
                <br/>
                <CardDeck>
                <SimpleCard running="Working 24/7" title="Updating the email and SMS list with new leads" description="Each time a new lead reaches out to us, I'll add their contact information to our Email and SMS lists for promotions." />
                <SimpleCard running="Working 24/7" title="Send follow up emails to existing patients" description="I'll check to see if patients haven't returned for a while and reach out to them." />
                </CardDeck>
                <br/>
                <CardDeck>
                <SimpleCard running="Once per month" title="Generate a report comparing ad spend, promotions, and revenue" description="Once per month, I'll add up our total ad spend and promotions, comparing to total revenue." />
                <SimpleCard running="Working 24/7" title="Generate sales oriented product descriptions" description="As new items are added to Shopify, I'll write sales oriented descriptions and update Shopify." />
                </CardDeck>
                <br /> */}

            </Container>
        </div>
    );
}

export default Sales;
