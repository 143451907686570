import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardDeck, CardBody, Nav, NavItem, NavLink, Navbar, Input, NavbarBrand, Button, Table } from 'reactstrap';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import '../App.css';
import botsHistory from '../images/bots-history.png'
import { FaPencilAlt } from 'react-icons/fa';
import { AiOutlineSearch } from 'react-icons/ai';
import moment from 'moment';

import { db } from '../firebase'; // Path to your firebase.js file
import { getFirestore, collection, onSnapshot, getDocs, query, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';


function History() {
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        const fetchLogs = async () => {
            try {
                const response = await fetch('https://imac-med-spa.labthree.ai/logs');
                if (response.ok) {
                    const data = await response.json();
                    setLogs(data.message);
                } else {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
            } catch (error) {
                console.error("Error fetching logs:", error);
            }
        };

        fetchLogs();
    }, []);

    const getBotColor = (bot) => {
        const botLower = bot.toLowerCase();
        switch (botLower) {
            case 'receptionist':
                return '#08CDEB';  // light turkoise blue
            case 'marketing':
                return '#16C1B5';  // deep green
            case 'sales':
                return '#9D76E3';  // purple
            case 'inventory':
                return '#E6D9FF';  // light purple
            default:
                return '#808080';  // gray
        }
    };


    const getStatusColor = (status) => {
        return status == 'completed' ? 'green' : 'gray';
    };



    return (
        <div className="App">
            <Container className="text-left">
                <br />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={botsHistory} alt="All Bots" style={{ height: '12em', marginRight: '5px' }} />
                    <div>
                        <h2 className="bold-text text-black">Work History</h2>
                        <p className=" text-black rounded-card" style={{ backgroundColor: '#fff' }}>See everything that's happening in real-time here! Every time a bot completes a job, you'll see a record of it here with additional details.</p>
                    </div>
                </div>
                <div>
                    <Table hover striped bordered style={{ backgroundColor: 'white' }}>
                        <thead>
                            <tr className="text-black font-weight-bold" style={{ fontSize: '0.9rem' }}>
                                <td>Date</td>
                                <td>Bot</td>
                                <td>Task</td>
                                <td>Description</td>
                                <td>Status</td>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.entries(logs)
                                // .sort((a, b) => new Date(b[0]) - new Date(a[0]))
                                .map(([date, logDetails]) => (
                                    <tr key={logDetails.timestamp.seconds*1000} className="text-black">
                                        <td><small className="font-weight-bold">{moment(logDetails.timestamp.seconds * 1000).subtract(8, 'hours').format("dddd, MMMM Do @ h:mm A")}</small></td>
                                        <td>
                                            <small style={{
                                                backgroundColor: getBotColor(logDetails.bot),
                                                borderRadius: '50px',
                                                padding: '5px 10px',
                                                color: 'white',
                                                fontWeight: 'bold'
                                            }}>
                                                {logDetails.bot}
                                            </small>
                                        </td>
                                        <td ><small className="font-weight-bold">{logDetails.task}</small></td>
                                        <td><small>{logDetails.description}</small></td>
                                        <td>
                                            <small style={{
                                                backgroundColor: getStatusColor(logDetails.status),
                                                borderRadius: '50px',
                                                padding: '5px 10px',
                                                color: 'white',
                                                fontWeight: 'bold'
                                            }}>
                                                {logDetails.status}
                                            </small>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                </div>

            </Container>
        </div>
    );
}

export default History;
