import React, { useState, useEffect, useMemo } from 'react';
import { Card, CardDeck, Button } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import "../../App.css"
import axios from 'axios';
import moment from 'moment';
import { Oval } from 'react-loader-spinner'
import { toast } from 'react-toastify';
import { IoIosAddCircle } from "react-icons/io";

const SpecialsManager = () => {
    const [specials, setSpecials] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState({ id: '', title: '', description: '', start_date: '', expiration_date: '' });
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [showCreateSpecialModal, setShowCreateSpecialModal] = useState(false);

    useEffect(() => {
        const fetchSpecials = async () => {
            setLoading(true);
            try {
                const response = await fetch('https://imac-med-spa.labthree.ai/job-110');
                const data = await response.json();
                setSpecials(data);
            } catch (error) {
                console.error('Error fetching specials:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchSpecials();
    }, []);

    const handleOpenModal = (special) => {
        setModalData(special);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setShowCreateSpecialModal(false);
        setModalData({ id: '', title: '', description: '', start_date: '', expiration_date: '' });
    };
    const handleCloseNewSpecialModal = () => {
        setShowCreateSpecialModal(false);
        setModalData({ id: '', title: '', description: '', start_date: '', expiration_date: '' });
    };

    const handleSaveModal = async () => {
        setLoading(true);
        try {
            await fetch('https://imac-med-spa.labthree.ai/job-110', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(modalData),
            });
            console.log(modalData)
            handleCloseModal();
            setShowCreateSpecialModal(false)
            // Refetch specials to update the list
            const response = await fetch('https://imac-med-spa.labthree.ai/job-110');
            const data = await response.json();
            setSpecials(data);
        } catch (error) {
            console.error('Error saving special:', error);
        } finally {
            setLoading(false);
        }
    };
    const handleDeleteModal = async () => {
        // setLoading(true);
        setDeleteConfirm(false)
        try {
            await fetch('https://imac-med-spa.labthree.ai/job-110/delete', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id: modalData.id }),
            });
            handleCloseModal();
            // Refetch specials to update the list
            const response = await fetch('https://imac-med-spa.labthree.ai/job-110');
            const data = await response.json();
            setSpecials(data);
        } catch (error) {
            console.error('Error deleting special:', error);
        } finally {
            // setLoading(false);
        }
    };
    const chunkArray = (array, chunkSize) => {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
    };
    const renderCardDecks = (specials) => {
        // Add an additional special for creating a new promotion
        const specialsPlusNew = [...specials, { id: 'new', title: 'Create a new special / promotion' }];

        const chunkedSpecials = chunkArray(specialsPlusNew, 3);
        return chunkedSpecials.map((chunk, index) => (
            <CardDeck key={index}>
                {chunk.map((special) => (
                    <Card
                        className="card-hover"
                        type="button"
                        onClick={() => special.id === 'new' ? setShowCreateSpecialModal(true) : handleOpenModal(special)}
                        key={special.id}
                        style={{
                            borderRadius: '10px',
                            border: '1px solid #CACACA',
                            marginBottom: '10px',
                            position: 'relative',
                            backgroundColor: special.id === 'new' ? '#f0f0f0' : 'white',
                            color: special.id === 'new' ? '#a0a0a0' : 'black',
                        }}
                    >
                        <div style={{ padding: '10px' }}>
                            <h5 className={special.id == "new" && `text-gray`} style={{ fontWeight: 'bold' }}>{special.title}</h5>
                            {special.id !== 'new' && (
                                <>
                                    <p>{special.description}</p>
                                    <p style={{ fontSize: 'smaller' }}>Start: {moment(special.start_date).format("MM/DD/YYYY")}</p>
                                    <p style={{ fontSize: 'smaller' }}>Expires: {moment(special.expiration_date).format("MM/DD/YYYY")}</p>
                                </>
                            )}
                            {special.id == 'new' && (
                                <>
                                    <p className="text-gray">Click here to create a new special / promotion. It will automatically appear on your website.</p>
                                    <h1 className="text-center text-gray">
                                        <IoIosAddCircle />

                                    </h1>
                                </>
                            )}

                        </div>
                    </Card>
                ))}
            </CardDeck>
        ));
    };




    return (
        <>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Oval color="#00BFFF" height={30} width={30} />
                </div>
            ) : (
                <>
                    {renderCardDecks(specials)}
                    <Modal centered isOpen={showModal} toggle={handleCloseModal} className="mx-auto" style={{ width: '50%', borderRadius: '15px' }}>
                        {/* <ModalHeader toggle={handleCloseModal}>Edit Special</ModalHeader> */}
                        <Card className="rounded-card p-5" style={{ backgroundColor: "#FFF4EE" }}>
                            <h4 className="text-center" style={{ fontWeight: 'bold', color: 'black' }}>Edit Special</h4>
                            <Form>
                                <FormGroup className="mb-3">
                                    <Label className="text-black font-weight-bold text-sm">Title</Label>
                                    <Input style={{ color: 'black' }} type="text" value={modalData.title} onChange={(e) => setModalData({ ...modalData, title: e.target.value })} />
                                </FormGroup>
                                <FormGroup className="mb-3">
                                    <Label className="text-black font-weight-bold text-sm">Description</Label>
                                    <Input style={{ color: 'black' }} type="textarea" value={modalData.description} onChange={(e) => setModalData({ ...modalData, description: e.target.value })} />
                                </FormGroup>
                                <FormGroup className="mb-3">
                                    <Label className="text-black font-weight-bold text-sm">Start Date</Label>
                                    <Input style={{ color: 'black' }} type="date" value={modalData.start_date} onChange={(e) => setModalData({ ...modalData, start_date: e.target.value })} />
                                </FormGroup>
                                <FormGroup className="mb-3">
                                    <Label className="text-black font-weight-bold text-sm">Expiration Date</Label>
                                    <Input style={{ color: 'black' }} type="date" value={modalData.expiration_date} onChange={(e) => setModalData({ ...modalData, expiration_date: e.target.value })} />
                                </FormGroup>
                                <Input type="hidden" value={modalData.id} />
                            </Form>

                            <div className={deleteConfirm && `rounded-card bg-white`} style={{ justifyContent: 'space-between', display: 'flex' }}>
                                {
                                    deleteConfirm ?
                                        <>
                                            <div>
                                                <div className="text-black font-weight-bold">Are you sure?</div>
                                                <small className="text-black">This action cannot be undone. <br />The special will be removed from your website.</small>
                                            </div>
                                            <div>
                                                <Button color="danger" className="rounded-card" onClick={handleDeleteModal}>Yes</Button>
                                                <Button className="rounded-card" onClick={() => { setDeleteConfirm(false) }}>No</Button>
                                            </div>
                                        </> :
                                        <>
                                            <div style={{ display: 'row' }}>
                                                <Button className="rounded-card" onClick={handleCloseModal}>Close</Button>
                                                <Button color="success" className="rounded-card" onClick={handleSaveModal}>Save Changes</Button>
                                            </div>
                                            <Button color="danger" className="rounded-card" onClick={() => { setDeleteConfirm(true) }}>Delete</Button>
                                        </>
                                }
                            </div>
                        </Card>
                    </Modal>
                    <Modal centered isOpen={showCreateSpecialModal} toggle={handleCloseNewSpecialModal} className="mx-auto" style={{ width: '50%', borderRadius: '15px' }}>
                        {/* <ModalHeader toggle={handleCloseModal}>Edit Special</ModalHeader> */}
                        <Card className="rounded-card p-5" style={{ backgroundColor: "#FFF4EE" }}>
                            <h4 className="text-center" style={{ fontWeight: 'bold', color: 'black' }}>Create a special / promotion</h4>
                            <Form>
                                <FormGroup className="mb-3">
                                    <Label className="text-black font-weight-bold text-sm">Title</Label>
                                    <Input style={{ color: 'black' }} type="text" value={modalData.title} onChange={(e) => setModalData({ ...modalData, title: e.target.value })} />
                                </FormGroup>
                                <FormGroup className="mb-3">
                                    <Label className="text-black font-weight-bold text-sm">Description</Label>
                                    <Input style={{ color: 'black' }} type="textarea" value={modalData.description} onChange={(e) => setModalData({ ...modalData, description: e.target.value })} />
                                </FormGroup>
                                <FormGroup className="mb-3">
                                    <Label className="text-black font-weight-bold text-sm">Start Date</Label>
                                    <Input style={{ color: 'black' }} type="date" value={modalData.start_date} onChange={(e) => setModalData({ ...modalData, start_date: e.target.value })} />
                                </FormGroup>
                                <FormGroup className="mb-3">
                                    <Label className="text-black font-weight-bold text-sm">Expiration Date</Label>
                                    <Input style={{ color: 'black' }} type="date" value={modalData.expiration_date} onChange={(e) => setModalData({ ...modalData, expiration_date: e.target.value })} />
                                </FormGroup>
                                <Input type="hidden" value={modalData.id} />
                            </Form>

                            <div className={deleteConfirm && `rounded-card bg-white`} style={{ justifyContent: 'space-between', display: 'flex' }}>
                                <div style={{ display: 'row' }}>
                                    <Button className="rounded-card" onClick={handleCloseModal}>Close</Button>
                                    <Button color="success" className="rounded-card" onClick={handleSaveModal}>Save Changes</Button>
                                </div>
                            </div>
                        </Card>
                    </Modal>
                </>
            )}
        </>
    );
};

export default SpecialsManager;