import React, { useState, useEffect, useRef } from 'react';
import { Container, Table, Row, Col, FormGroup, Label, Card, Modal, CardDeck, CardBody, Nav, NavItem, NavLink, Navbar, Input, NavbarBrand, Button } from 'reactstrap';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'; // import these at the top of your file
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { BsFillGearFill } from 'react-icons/bs';
import SpecialsManager from './tools/SpecialsManager';
import ReactIosSwitch from 'react-ios-switch';
import '../App.css';
import greenBot from '../images/green-bot.png'
import { FaPencilAlt } from 'react-icons/fa';
import { IoAddCircle } from 'react-icons/io5';
import axios from 'axios';
import { db } from '../firebase'; // Path to your firebase.js file
import { collection, onSnapshot, getDocs, getDoc, query, doc, setDoc, updateDoc } from 'firebase/firestore';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { InfinitySpin } from "react-loader-spinner";
import moment from 'moment';
import BlogWriter from './tools/BlogWriter';




function Marketing() {
    const [refreshPage, setRefreshPage] = useState(false)

    const isUserOnMobile = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
    };

    const isMobile = isUserOnMobile();

    function SimpleCard({ id, running, title, description, children, expand = false }) {
        const [expanded, setExpanded] = useState(expand); // State to toggle the description
        const [jobActive, setJobActive] = useState(true);
        const jobRef = doc(db, 'Jobs', 'Marketing');

        useEffect(() => {
            const fetchData = async () => {
                const docSnap = await getDoc(jobRef);
                if (docSnap.exists()) {
                    setJobActive(docSnap.data()[`job_${id}`]);
                }
            };
            fetchData();
        }, []);


        const toggleJob = async () => {
            await updateDoc(jobRef, { [`job_${id}`]: !jobActive });
            setJobActive(!jobActive);
        };


        return (
            <>
                <Card body className="rounded-card">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                            <div style={{ left: '10px', display: 'flex', alignItems: 'center' }}>
                                {
                                    id == "" ?
                                        <>
                                        </> :
                                        <>
                                            {
                                                jobActive ?
                                                    <>
                                                        <div style={{ width: '10px', height: '10px', backgroundColor: '#90EE90', borderRadius: '50%', animation: 'pulsate 2s infinite' }}></div>
                                                        <span style={{ fontSize: '15px', marginLeft: '5px' }}>{running}</span>
                                                    </> :
                                                    <>
                                                        <div style={{ width: '10px', height: '10px', backgroundColor: 'grey', borderRadius: '50%', animation: 'pulsate 2s infinite' }}></div>
                                                        <span style={{ fontSize: '15px', marginLeft: '5px' }}>Inactive</span>
                                                    </>
                                            }
                                        </>
                                }

                            </div>
                            <h5 type="button" onClick={() => { setExpanded(!expanded) }} className="bold-text text-black">{title}</h5>
                            <p className="">{description}</p>
                        </div>
                        {expanded && id !== "" &&
                            <div className="pt-2 pr-2" style={{ position: 'absolute', top: '0', right: '0' }}>
                                <ReactIosSwitch
                                    checked={jobActive}
                                    onChange={toggleJob}
                                />
                            </div>
                        }
                    </div>
                    <div>
                        {expanded && children}
                    </div>
                </Card >
            </>
        )
    }

    function SMSBlacklist() {
        const [blacklist, setBlacklist] = useState([]);
        const [filteredNumbers, setFilteredNumbers] = useState([]);
        const [searchTerm, setSearchTerm] = useState("");
        const [selectedNumbers, setSelectedNumbers] = useState({});
        const blacklistRef = doc(db, "Jobs", "SMS Blacklist");

        useEffect(() => {
            const fetchData = async () => {
                const docSnap = await getDoc(blacklistRef);
                if (docSnap.exists()) {
                    const numbers = docSnap.data().phone_numbers;
                    setBlacklist(numbers);
                    setFilteredNumbers(numbers);
                }
            };
            fetchData();
        }, []);

        useEffect(() => {
            const filtered = blacklist.filter(number =>
                number.includes(searchTerm.replace(/\D/g, ''))
            );
            setFilteredNumbers(filtered);
        }, [searchTerm, blacklist]);

        const handleCheckboxChange = (number) => {
            setSelectedNumbers(prev => ({
                ...prev,
                [number]: !prev[number]
            }));
        };

        const addNumber = async () => {
            // Replace this with your logic to input a new number
            const newNumber = prompt("Enter new phone number:");
            if (newNumber) {
                const updatedBlacklist = [...blacklist, newNumber];
                setBlacklist(updatedBlacklist);
                await updateDoc(blacklistRef, { phone_numbers: updatedBlacklist });
            }
        };

        const deleteSelectedNumbers = async () => {
            const remainingNumbers = blacklist.filter(number => !selectedNumbers[number]);
            setBlacklist(remainingNumbers);
            setSelectedNumbers({});
            await updateDoc(blacklistRef, { phone_numbers: remainingNumbers });
        };

        return (
            <>
                <div style={{ display: 'flex', alignItems: 'center', background: '#f7f7f7', padding: '10px', borderRadius: '5px' }}>
                    <input
                        type="text"
                        placeholder="Search..."
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{ flexGrow: 1, border: '1px solid #ddd', borderRadius: '5px', padding: '10px', marginRight: '10px' }}
                    />
                    <button onClick={addNumber} style={{ padding: '10px 15px', background: '#5cb85c', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
                        +
                    </button>
                </div>
                {Object.keys(selectedNumbers).some(k => selectedNumbers[k]) && (
                    <button onClick={deleteSelectedNumbers} style={{ marginTop: '10px', padding: '10px 15px', background: '#d9534f', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
                        Delete Selected
                    </button>
                )}
                <ul style={{ maxHeight: 'calc(12 * 1.5em)', overflowY: 'auto', listStyleType: 'none', padding: '10px', marginTop: '10px', border: '1px solid #ddd', borderRadius: '5px' }}>
                    {filteredNumbers.map((number, index) => (
                        <li key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid #eee', padding: '8px 0' }}>
                            <span>{number}</span>
                            <input
                                type="checkbox"
                                checked={!!selectedNumbers[number]}
                                onChange={() => handleCheckboxChange(number)}
                                style={{ marginLeft: '10px' }}
                            />
                        </li>
                    ))}
                </ul>
            </>
        );
    }


    function InstagramReplies() {
        const [expanded, setExpanded] = useState(false);
        const [textValue, setTextValue] = useState(`You are an all-star sales person that specializes in Social Media management. You love replying to people in Instagram comments while creatively working on driving users to IMAC MedSpa for aesthetic procedures that can help them become the best versions of themselves!`);
        const [isEditing, setIsEditing] = useState(false);
        const [instructions, setInstructions] = useState(false);
        const [dropdownOpen, setDropdownOpen] = useState(false); // Add this line to manage dropdown state

        const [jobActive, setJobActive] = useState(true);

        async function toggleJob() {
            console.log('test')
        }

        const toggle = () => setDropdownOpen(prevState => !prevState); // Function to toggle dropdown

        return (
            <>
                <Card body className="rounded-card">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                            <div style={{ left: '10px', display: 'flex', alignItems: 'center' }}>
                                {
                                    jobActive ?
                                        <>
                                            <div style={{ width: '10px', height: '10px', backgroundColor: '#90EE90', borderRadius: '50%', animation: 'pulsate 2s infinite' }}></div>
                                            <span style={{ fontSize: '15px', marginLeft: '5px' }}>Working 24/7</span>
                                        </> :
                                        <>
                                            <div style={{ width: '10px', height: '10px', backgroundColor: 'grey', borderRadius: '50%', animation: 'pulsate 2s infinite' }}></div>
                                            <span style={{ fontSize: '15px', marginLeft: '5px' }}>Inactive</span>
                                        </>
                                }
                            </div>
                            <h5 type="button" onClick={() => { setExpanded(!expanded) }} className="bold-text text-black">Replying to Instagram comments</h5>
                            <p className="">Whenever someone comments on any of our Instagram posts, I'll reply.</p>
                        </div>
                        {expanded &&
                            <div className="pt-2 pr-2" style={{ position: 'absolute', top: '0', right: '0' }}>
                                <ReactIosSwitch
                                    checked={jobActive}
                                    onChange={toggleJob}
                                />
                            </div>
                        }
                    </div>
                    {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                        <div style={{ left: '10px', display: 'flex', alignItems: 'center' }}>
                            <div style={{ width: '10px', height: '10px', backgroundColor: '#90EE90', borderRadius: '50%', animation: 'pulsate 2s infinite' }}></div>
                            <span style={{ fontSize: '15px', marginLeft: '5px' }}>Working 24/7</span>
                        </div>
                    </div>
                    <h5 type="button" onClick={() => { setExpanded(!expanded) }} className="bold-text text-black">Replying to Instagram comments</h5>
                        <p className="">Whenever someone comments on any of our Instagram posts, I'll reply.</p> */}
                    <div>

                        {
                            expanded ?
                                <>

                                    <br />
                                    <small type="button" onClick={() => { setInstructions(!instructions) }} className='text-primary bold-text'>View Instructions</small>
                                    <br />
                                    {
                                        instructions &&
                                        <>
                                            <Card className="no-border rounded-card speech-bubble-2">
                                                {isEditing ? (
                                                    <>
                                                        <textarea
                                                            value={textValue}
                                                            onChange={(e) => setTextValue(e.target.value)}
                                                            rows={4}
                                                            cols={500}
                                                            style={{ width: '100%' }}
                                                            className="med-text rounded-card no-border"
                                                        />
                                                        <br />
                                                        <div className="text-center">
                                                            <Button color="primary" className="rounded-card med-text" onClick={() => { setIsEditing(false) }}>Save</Button>
                                                            <Button color="secondary" className="rounded-card med-text" onClick={() => { setIsEditing(false) }}>Close</Button>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div onClick={() => setIsEditing(true)}>
                                                        <span className="speech-text med-text">{textValue}</span>
                                                        <FaPencilAlt className="pencil-icon" />
                                                    </div>
                                                )}
                                            </Card>
                                        </>
                                    }

                                    <small type="button" className='text-danger bold-text'>Turn off bot</small>
                                </> :
                                <>
                                </>
                        }
                    </div>
                </Card >
            </>
        )
    }
    function ContactManagement() {
        const [expanded, setExpanded] = useState(false);
        const [dropdownOpen, setDropdownOpen] = useState(false); // Add this line to manage dropdown state
        const jobRef = doc(db, 'Jobs', 'Marketing');
        const [jobActive, setJobActive] = useState(true);
        const [contacts, setContacts] = useState([]); // Array for contacts
        const [contactCount, setContactCount] = useState(0); // Count of contacts
        const [modal, setModal] = useState(false); // New state for modal

        const toggleModal = () => setModal(!modal);

        useEffect(() => {
            const fetchData = async () => {
                const docSnap = await getDoc(jobRef);
                if (docSnap.exists()) {
                    setJobActive(docSnap.data().job_9);
                }
            };
            fetchData();
        }, []);
        useEffect(() => {
            const fetchContacts = async () => {
                try {
                    const response = await axios.get('https://imac-med-spa.labthree.ai/get-contacts');
                    setContacts(response.data);
                    setContactCount(response.data.length);
                } catch (error) {
                    console.log('Error fetching contacts:', error);
                }
            };
            fetchContacts();
        }, []);

        const toggleJob = async () => {
            await updateDoc(jobRef, { 'job_9': !jobActive });
            setJobActive(!jobActive);
        };
        const toggle = () => setDropdownOpen(prevState => !prevState); // Function to toggle dropdown

        return (
            <>
                <Card body className="rounded-card">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                            <div style={{ left: '10px', display: 'flex', alignItems: 'center' }}>
                                {
                                    jobActive ?
                                        <>
                                            <div style={{ width: '10px', height: '10px', backgroundColor: '#90EE90', borderRadius: '50%', animation: 'pulsate 2s infinite' }}></div>
                                            <span style={{ fontSize: '15px', marginLeft: '5px' }}>Working 24/7</span>
                                        </> :
                                        <>
                                            <div style={{ width: '10px', height: '10px', backgroundColor: 'grey', borderRadius: '50%', animation: 'pulsate 2s infinite' }}></div>
                                            <span style={{ fontSize: '15px', marginLeft: '5px' }}>Inactive</span>
                                        </>
                                }
                            </div>
                            <h5 type="button" onClick={() => { setExpanded(!expanded) }} className="bold-text text-black">Managing contacts in a single list</h5>
                            <p className="">I will automatically update your contact list when new patients are added to Dr Chrono.</p>
                        </div>
                        {expanded &&
                            <div className="pt-2 pr-2" style={{ position: 'absolute', top: '0', right: '0' }}>
                                <ReactIosSwitch
                                    checked={jobActive}
                                    onChange={toggleJob}
                                />
                            </div>
                        }
                    </div>

                    {
                        expanded ?
                            <>
                                <Button className="rounded-card" onClick={toggleModal}>
                                    View all {contactCount} contacts
                                </Button>
                                <br />
                                {/* <Button className="no-border bg-transparent no-shadow" onClick={toggleModal}>
                                    Add a new contact
                                </Button> */}
                                <Modal className="ml-8" size="lg" centered="true" isOpen={modal} toggle={toggleModal}>
                                    <Table style={{ backgroundColor: 'white' }}>
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>Phone</th>
                                                <th>Email</th>
                                                <th>Address</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {contacts.map((contact, index) => (
                                                <tr key={index}>
                                                    <td>{contact.ID}</td>
                                                    <td>{contact.NAME}</td>
                                                    <td>{contact.PHONE}</td>
                                                    <td>{contact.EMAIL}</td>
                                                    <td style={{ wordWrap: 'break-word', maxWidth: '150px' }}>{contact.ADDRESS}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Modal>
                            </> :
                            <>
                            </>
                    }
                </Card >
            </>
        )
    }
    const renderers = {
        h1: ({ children }) => <h1 style={{ fontSize: '30px', color: 'black', fontFamily: 'SF Bold' }}>{children}</h1>,
        h2: ({ children }) => <h2 style={{ fontSize: '25px', color: 'black', fontFamily: 'SF Regular' }}>{children}</h2>,
        h3: ({ children }) => <h3 style={{ fontSize: '20px', color: 'black', fontFamily: 'SF Medium' }}>{children}</h3>,
        p: ({ children }) => <p style={{ fontSize: '16px', color: 'black', fontFamily: "SF Regular" }}>{children}</p>,
        // Add other elements as needed
    };


    function PromoCard({ endDate, name, price, quantity, url, index, id, status, sms, sent_date, creation_date }) {
        const [expanded, setExpanded] = useState(false);
        const [loading, setLoading] = useState(false);
        const [showSMS, setShowSMS] = useState(false);
        const [sent, setSent] = useState(null);
        const [sentDate, setSentDate] = useState(null);
        const [imageLoading, setImageLoading] = useState(false)
        const [imageURL, setImageURL] = useState(null)

        let productID = id;
        let sms_message = "";
        let html_message = "";

        if (sms) {
            sms_message = sms.replace(/\\n/g, "\n").replace(/"/g, '');
            html_message = sms_message.replace(/\n/g, '<br />');
        }
        const [editableSMS, setEditableSMS] = useState(sms_message)
        const promotionsRef = doc(db, 'Promotions', productID);

        const handleImageUpload = async (e) => {
            setImageLoading(true)
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = async () => {
                try {
                    const base64Image = reader.result.split(',')[1];
                    const productId = id; // Replace with actual ID
                    const response = await axios.post(
                        `https://imac-marketing-bot.glitch.me/api/products/${productId}/image`,
                        { image: base64Image }
                    );
                    console.log('Image Upload Response:', response.data);
                    const firestoreDoc = doc(db, 'Promotions', productId);
                    const data = {
                        main_image: response.data.image.src,
                        status: "Ready"
                    };
                    updateDoc(firestoreDoc, data).then(value => {
                        const somedata = {
                            "message": "🟢 *Update:*\n\nImage uploaded to Shopify for campaign. Status has been updated."
                        }
                        axios.post('https://hook.us1.make.com/q0bvvt3cj6u2cgaiaiejuv8l4xo1yp8i', somedata).then(value => {
                            console.log("Slack sent.")
                            setImageLoading(false)
                            setRefreshPage(!refreshPage)
                        })
                    })

                } catch (error) {
                    console.error('Image Upload Failed:', error);
                }
            };
        };

        useEffect(() => {
            const fetchData = async () => {
                const docRef = doc(db, 'Promotions', id);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    let main_image = docSnap.data().main_image;
                    if (main_image) {
                        setImageURL(main_image);
                    }
                }
            };

            fetchData();
        }, []);

        useEffect(() => {
            const unsubscribe = onSnapshot(promotionsRef, (doc) => {
                const data = doc.data();
                setSent(data.sent);
                setSentDate(data.sent_date);
            });

            if (status != "Active") {
                setShowSMS(true)
            }

            return () => unsubscribe(); // Clean up
        }, []);

        async function handleSMSChange() {
            let smsCleaned = editableSMS.replace(/<div><\/div>/g, '\n'); // Replace empty divs with \n
            smsCleaned = smsCleaned.replace(/<div>/g, ''); // Remove opening <div>
            smsCleaned = smsCleaned.replace(/<\/div>/g, '\n'); // Replace closing </div> with \n
            smsCleaned = smsCleaned.replace(/<br>/g, '\n'); // Replace <br> with \n
            smsCleaned = smsCleaned.replace(/&nbsp;/g, ''); // Remove random text
            console.log(smsCleaned)
            const data = { sms_message: smsCleaned };
            await updateDoc(promotionsRef, data);
        }

        const sendSMS = async (productID) => {
            try {
                const response = await axios.post('https://imac-marketing-bot.glitch.me/api/sendSMS', { productID });
                console.log('Successfully sent SMS:', response.data);
                const data = {
                    status: "Active",
                    sent_date: new Date()
                };
                updateDoc(promotionsRef, data).then(value => {
                    setRefreshPage(!refreshPage)
                })
            } catch (error) {
                console.error('Error sending SMS:', error);
            }
        };

        const handleEditableDivChange = (e) => {
            setEditableSMS(e.target.innerHTML);
        };

        async function deactivateListing() {
            setLoading(true)
            const data = {
                status: "inactive"
            };
            await updateDoc(promotionsRef, data);
            console.log('Document successfully updated!');
            const url = "https://imac-marketing-bot.glitch.me/api/products/" + productID;
            const shopifyData = {
                product: {
                    status: "draft"
                }
            };
            axios.put(url, shopifyData)
                .then(response => {
                    console.log('Product updated successfully:', response.data);
                    setLoading(false)
                    document.location.reload()
                })
                .catch(error => {
                    console.error('Error updating product:', error);
                    setLoading(false)
                });
        }



        return (
            <>
                <Card key={index} body className="rounded-card">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                            <div style={{ left: '10px', display: 'flex', alignItems: 'center' }}>
                                {
                                    (status == "Active" || status == "Approved" || status == "Ready") &&
                                    <>
                                        <div style={{ width: '10px', height: '10px', backgroundColor: '#90EE90', borderRadius: '50%', animation: 'pulsate 2s infinite' }}></div>
                                    </>
                                }
                                {
                                    status == "Waiting for Design" &&
                                    <>
                                        <div style={{ width: '10px', height: '10px', backgroundColor: 'orange', borderRadius: '50%', animation: 'pulsate 2s infinite' }}></div>
                                    </>
                                }
                                <span style={{ fontSize: '15px', marginLeft: '5px' }}>
                                    {/* Ends on {endDate} */}
                                    {status}
                                </span>
                            </div>
                            <h5 type="button" onClick={() => { setExpanded(!expanded) }} className="bold-text text-black">{name}</h5>
                        </div>
                    </div>

                    {
                        expanded &&
                        <>
                            <p><span className="bold-text">Promo URL: </span><a className="font-italic text-blue" target="_blank" href={url}>{url}</a></p>
                            {
                                imageURL ?
                                    <div className="text-center" style={{ maxHeight: '200px', overflow: 'hidden' }}>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            id="file-upload"
                                            onChange={handleImageUpload}
                                        />
                                        <img onClick={() => document.getElementById('file-upload').click()} src={imageURL} alt="Main" style={{ maxHeight: '200px', width: 'auto' }} />
                                    </div>
                                    : null
                            }
                            {
                                (status == "Waiting for Design" && imageLoading == false) &&
                                <>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        id="file-upload"
                                        onChange={handleImageUpload}
                                    />
                                    <button className="rounded-card" onClick={() => document.getElementById('file-upload').click()}>
                                        Upload your own design
                                    </button>
                                </>
                            }
                            {
                                imageLoading &&
                                <>
                                    <div className="text-center">
                                        <InfinitySpin
                                            type="ThreeDots"
                                            color="#00BFFF"
                                            height={100}
                                            width={100}
                                        />
                                        <p>Uploading image...</p>
                                    </div>
                                </>
                            }

                            {
                                status === "Approved" &&
                                <>
                                    <CardDeck>
                                        <Card className="text-center rounded-card">
                                            <span className="med-text">Total Recipients</span>
                                            <h4 className="med-text text-black">1293</h4>
                                        </Card>
                                        <Card className="text-center rounded-card">
                                            <span className="med-text">Total Purchases</span>
                                            <h4 className="med-text text-black">12</h4>
                                        </Card>
                                        <Card className="text-center rounded-card">
                                            <span className="med-text">Sales</span>
                                            <h4 className="med-text text-black">$1,200.25</h4>
                                        </Card>
                                    </CardDeck>
                                </>
                            }

                            <br />
                            <small type="button" onClick={() => { setShowSMS(!showSMS) }} className='text-primary bold-text'>View SMS Message</small>
                            {
                                showSMS &&
                                <>
                                    {
                                        sms === undefined ?
                                            <>
                                                <Card className="rounded-card">
                                                    <InfinitySpin
                                                        type="ThreeDots"
                                                        color="#00BFFF"
                                                        height={100}
                                                        width={100}
                                                    />
                                                    <p>AI is generating the SMS...</p>
                                                </Card>
                                            </> :
                                            <>

                                                <div
                                                    className="rounded-card"
                                                    contentEditable
                                                    onInput={handleEditableDivChange}
                                                    dangerouslySetInnerHTML={{ __html: html_message }}
                                                />
                                            </>
                                    }
                                    {
                                        status === 'Ready' &&
                                        <>
                                            <div className="py-2 text-center">

                                                <Button className="rounded-card" onClick={() => { handleSMSChange() }}>Save Changes</Button>
                                                <Button className="rounded-card" color="primary" onClick={() => { sendSMS(id) }}>Send SMS Blast</Button>
                                            </div>
                                        </>
                                    }

                                </>

                            }

                            {loading ?
                                <div className="text-center">
                                    <InfinitySpin
                                        type="ThreeDots"
                                        color="#00BFFF"
                                        height={100}
                                        width={100}
                                    />
                                    <p>Ending promotion...</p>
                                </div> :
                                <>
                                    <small type="button" onClick={() => { deactivateListing() }} className='text-danger bold-text'>End promotion</small>
                                </>
                            }
                        </>
                    }
                </Card>
                <br />
            </>
        )
    }
    async function fetchGPT3Response(prompt, context) {
        const apiKey = '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';
        const endpoint = 'https://api.openai.com/v1/chat/completions';

        const headers = {
            'Authorization': `Bearer ${apiKey}`,
            'Content-Type': 'application/json'
        };


        const data = {
            model: "gpt-3.5-turbo-0613",
            temperature: 0.7,
            messages: [
                {
                    role: "system",
                    content: context
                },
                {
                    role: "user",
                    content: prompt
                }
            ]
        };

        try {
            const response = await axios.post(endpoint, data, { headers });
            const generatedText = response.data.choices[0].message.content;
            console.log(generatedText);
            return generatedText;
        } catch (error) {
            console.error('Error fetching GPT-3 response:', error);
        }
    }

    function Promotion() {
        const [updater, setUpdater] = useState(false);
        const [showModal, setShowModal] = useState(false);
        const [promos, setPromos] = useState([])
        const [showInactive, setShowInactive] = useState(false);
        const [isLoading, setIsLoading] = useState(false);

        const nameRef = useRef();
        const priceRef = useRef();
        const quantityRef = useRef();
        const endDateRef = useRef();

        function toggleModal() {
            setShowModal(!showModal);
        };

        useEffect(() => {
            const fetchData = async () => {
                const promoCollection = collection(db, 'Promotions');
                const promoSnapshot = await getDocs(promoCollection);
                const promoList = promoSnapshot.docs.map(doc => doc.data());
                const list = [];

                for (let i = 0; i < promoList.length; i++) {
                    list.push(promoList[i]);
                }
                console.log(promoList)
                setPromos(list);
            };

            fetchData();
            console.log('FETCHED DATA AGAIN')
        }, [updater]);


        async function submitPromotion(name, price, quantity, endDate) {
            setIsLoading(true)
            let promoDescription;
            fetchGPT3Response(
                "Reply with a promotional description for this promotion: \nName: " + name + "\nPrice: " + price + "\nEnd date: " + endDate,
                "You are a creative director for a MedSpa company and you help sales people write compelling Promotion descriptions. Sales people will send you the name of their promotion and you will respond with a short, catchy, sales description of the promotion. People will see this description when they purchase a promotion so it must sell and be aggressive and feminine."
            ).then(value => {
                promoDescription = value;
                const requestBody = {
                    "product": {
                        "title": name,
                        "body_html": promoDescription,
                        "variants": [
                            {
                                "price": price,
                                "inventory_quantity": quantity,
                                "requires_shipping": false
                            }
                        ]
                    }
                }

                axios.post('https://imac-marketing-bot.glitch.me/api/products', requestBody)
                    .then(response => {
                        const url = 'https://de868f.myshopify.com/products/' + response.data.product.handle
                        // const docRef = db.collection('Promotions').doc(response.data.product.id);
                        const promotionsCollection = collection(db, 'Promotions');
                        const customDoc = doc(promotionsCollection, String(response.data.product.id));


                        fetchGPT3Response(
                            `Rewrite the following promotion description as an SMS message. Use "\n" for new lines. Use simple language and try to keep it short. You must include the URL in full:

                Name: ${name}
                Description: ${promoDescription}
                URL to purchase: ${url}`,

                            `
                You are an amazing marketer that specializes in turning promotion descriptions into nicely styled SMS messages that draw customers to purchase from "IMAC MedSpa", a top MedSpa located in Santa Clarita, CA.

                Your text message should follow this format with a creative twist and more emojis:
                IMAC MED SPA:

                New service alert!

                Your weight loss journey awaits.

                Now offering Semaglutide shots at IMAC Med Spa.

                Semagluatide, known by name brand Ozempic, is a revolutionary weight loss aid that decreases appetite.

                Order today by visiting ${url}

                Hours:
                Tue - Fri: 10 AM - 6 PM
                Selected Saturdays: 10 AM - 4 PM

                📍 - 24237 Main St., Newhall, CA 91321
                🖥️ - www.Imacmedspa.comReply stop to stop`
                        ).then(value => {
                            const data = {
                                name: name,
                                price: price,
                                quantity: quantity,
                                endDate: endDate,
                                product_id: String(response.data.product.id),
                                url: url,
                                status: "Waiting for Design",
                                description: promoDescription,
                                sms_message: value,
                                creation_date: new Date(),
                                main_image: ''
                            };

                            console.log('Product created successfully:', response.data);
                            setDoc(customDoc, data).then(something => {
                                const message = {
                                    "message": "*New IMAC Campaign*\n\nStatus: `Waiting for Design`\n\n> • Log into IMAC dashboard\n> • Create and upload design\n> • Set `status` to `Approval needed`"
                                }
                                axios.post('https://hook.us1.make.com/q0bvvt3cj6u2cgaiaiejuv8l4xo1yp8i', message)
                                setUpdater(!updater)
                                setIsLoading(false)
                            })
                        })
                    })
                    .catch(error => {
                        console.error('Error creating product:', error);
                        setIsLoading(false)
                    });
            })


        }


        const handleSubmit = () => {
            const name = nameRef.current.value;
            const price = priceRef.current.value;
            const quantity = quantityRef.current.value;
            const endDate = endDateRef.current.value;
            submitPromotion(name, price, quantity, endDate);
            toggleModal(); // Close the modal
        };

        return (
            <>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>

                    <h3 className="bold-text text-black">
                        SMS Campaigns <span type="button" onClick={toggleModal}><IoAddCircle /></span>
                    </h3>
                    {
                        showInactive ?
                            <>
                                <small type="button" onClick={() => { setShowInactive(false) }} className="font-italic">Hide inactive</small>
                            </> :
                            <>
                                <small type="button" onClick={() => { setShowInactive(true) }} className="font-italic">Show inactive</small>
                            </>
                    }
                </div>
                {
                    isLoading &&
                    <>
                        <br />
                        <div className="text-center">
                            <InfinitySpin
                                type="ThreeDots"
                                color="#00BFFF"
                                height={100}
                                width={100}
                            />
                            <p>AI-generating promotion...</p>
                        </div>
                    </>
                }
                {
                    showInactive ?
                        <>
                            {promos
                                .sort((a, b) => {
                                    if (a.status === "active" && b.status !== "active") return -1;
                                    if (b.status === "active" && a.status !== "active") return 1;
                                    return b.creation_date.toDate() - a.creation_date.toDate();
                                })
                                .map((promos, index) => {
                                    return (
                                        <PromoCard
                                            endDate={promos.endDate}
                                            name={promos.name}
                                            price={promos.price}
                                            quantity={promos.quantity}
                                            url={promos.url}
                                            index={index}
                                            id={promos.product_id}
                                            status={promos.status}
                                            sms={promos.sms_message}
                                            sent_date={promos.sent_date ? promos.sent_date.toDate().toLocaleDateString() : null}
                                            creation_date={promos.creation_date ? promos.creation_date.toDate().toLocaleDateString() : null}
                                        />
                                    );
                                })}

                        </> :
                        <>
                            {promos
                                .sort((a, b) => b.creation_date.toDate() - a.creation_date.toDate())
                                .map((promos, index) => {
                                    if (promos.status !== "Inactive") {
                                        return (
                                            <PromoCard
                                                endDate={promos.endDate}
                                                name={promos.name}
                                                price={promos.price}
                                                quantity={promos.quantity}
                                                url={promos.url}
                                                index={index}
                                                id={promos.product_id}
                                                status={promos.status}
                                                sms={promos.sms_message}
                                                sent_date={promos.sent_date ? promos.sent_date.toDate().toLocaleDateString() : null}
                                                creation_date={promos.creation_date ? promos.creation_date.toDate().toLocaleDateString() : null}
                                            />
                                        );
                                    }
                                })}
                        </>
                }


                <Modal size="lg" centered="true" isOpen={showModal} toggle={toggleModal}>
                    <Card>
                        <CardBody>
                            <h4 className="bold-text">Create your promotion</h4>
                            <p className="">Here, you can create a promotion and send it all of your customers. They'll receive a text with the promotional content and a link to purchase.</p>
                            <FormGroup>
                                {/* <Label for="promotionName">Type</Label> */}
                                {/* <select className="rounded-card mx-2" id="promotionType" ref={typeRef} defaultValue="Email">
                                    <option value="Email">Email</option>
                                    <option value="SMS">SMS</option>
                                </select> */}
                            </FormGroup>
                            <FormGroup>
                                <Label for="promotionName">Promotion Name</Label>
                                <Input type="text" id="promotionName" innerRef={nameRef} />
                            </FormGroup>
                            <Row style={{ justifyContent: "center" }}>
                                <FormGroup className="mx-1 text-center">
                                    <Label for="price">Price</Label>
                                    <Input type="number" id="price" className='text-black' innerRef={priceRef} />
                                </FormGroup>
                                <FormGroup className="mx-1 text-center">
                                    <Label for="quantity">Quantity</Label>
                                    <Input type="number" id="quantity" className='text-black' innerRef={quantityRef} />
                                </FormGroup>
                                <FormGroup className="mx-1 text-center">
                                    <Label for="endDate">End Date</Label>
                                    <Input type="date" id="endDate" className='text-black' innerRef={endDateRef} />
                                </FormGroup>
                            </Row>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox" checked={true} disabled={true} /> Send to all contacts
                                </Label>
                            </FormGroup>
                        </CardBody>
                        <div className="text-center">
                            <Button className="rounded-card" color="primary" onClick={handleSubmit}>Publish</Button>
                            <Button className="rounded-card" color="secondary" onClick={toggleModal}>Close</Button>
                        </div>
                        <br />

                    </Card>
                </Modal>
            </>
        )
    }
    // imgbb API KEY e0788f31f675c76d6e18e5d7e8909f7a


    function SMSHistory() {
        const [blasts, setBlasts] = useState([]);
        const [modal, setModal] = useState(false);
        const [selectedBlast, setSelectedBlast] = useState(null);

        const toggleModal = () => setModal(!modal);

        const openPopup = (blast) => {
            console.log(blast)
            setSelectedBlast(blast);
            toggleModal();
        };

        useEffect(() => {
            const fetchData = async () => {
                try {
                    const response = await fetch('https://imac-med-spa.labthree.ai/sms-history');
                    if (response.ok) {
                        const data = await response.json();
                        const info = data.message;
                        // Assuming the response data is an array of objects with a 'datetime' property
                        const sortedBlasts = info.sort((a, b) => new Date(b.datetime) - new Date(a.datetime));
                        setBlasts(sortedBlasts);
                    } else {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                } catch (error) {
                    console.error("Error fetching SMS history:", error);
                }
            };
        
            fetchData();
        }, []);

        return (
            <>
                <Table hover striped bordered>
                    <tr className="text-black">
                        {
                            isMobile ?
                                <>
                                    <th>Date</th>
                                    <th>Messages Sent</th>
                                </> :
                                <>
                                    <th>Date</th>
                                    <th>Successes</th>
                                    <th>Fails</th>
                                    <th>Audience</th>
                                    <th>Cost</th>
                                </>
                        }

                    </tr>
                    {blasts.map((blast, index) => (
                        <tr key={index} className="text-black">
                            {
                                isMobile ?
                                    <>
                                        <td onClick={() => openPopup(blast)}>
                                            {moment.unix(Number(blast.datetime / 1000)).format("MM/DD/YYYY")}
                                            <br />
                                            <small>
                                                {moment.unix(Number(blast.datetime / 1000)).format("@ h:mm A")}
                                            </small>
                                        </td>
                                        <td>
                                            {blast.success}
                                            <br />
                                            <small><a href={blast.success_link}>Report</a></small>
                                        </td>
                                    </> :
                                    <>
                                        <td onClick={() => openPopup(blast)}>
                                            {moment.unix(Number(blast.datetime / 1000)).format("MM/DD/YYYY")}
                                            <br />
                                            <small>
                                                {moment.unix(Number(blast.datetime / 1000)).format("@ h:mm A")}
                                            </small>
                                        </td>
                                        <td>
                                            {blast.success}
                                            <br />
                                            <small><a href={blast.success_link}>Report</a></small>
                                        </td>
                                        <td>
                                            {blast.fail}
                                            <br />
                                            <small><a href={blast.fail_link}>Report</a></small>
                                        </td>
                                        <td>
                                            {blast.target_audience == 'all' ? 'All contacts' : blast.target_audience}
                                        </td>
                                        <td>
                                            <span className="font-weight-bold">{blast.cost ? '$' + blast.cost : ''}</span>
                                            <br />
                                            {
                                                blast.simpleText &&
                                                <small style={{ fontStyle: 'italic' }}>SimpleText: <span className="font-weight-bold">{'$' + blast.simpleText}</span></small>
                                            }

                                        </td>
                                    </>
                            }

                        </tr>
                    ))}
                </Table>
                <Modal isOpen={modal} toggle={toggleModal} centered={true} style={{ width: isMobile ? '90%' : '50%' }}>
                    <Card className="rounded-card">
                        {
                            selectedBlast && <img
                                src={selectedBlast.imageUrl}
                                style={{
                                    maxHeight: '200px',
                                    objectFit: 'contain'
                                }}
                            />
                        }
                        <pre style={{ fontSize: '0.8em', whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{selectedBlast ? selectedBlast.message : 'No message available'}</pre>
                    </Card>
                </Modal>

            </>
        );
    }





    return (
        <div className="App">
            <Container className="text-left">
                <br />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={greenBot} alt="Green Bot" style={{ height: '10em', marginRight: '5px' }} />
                    <div>
                        <h2 className="bold-text text-black">Marketing Bot</h2>
                        <p className=" text-black rounded-card" style={{ backgroundColor: '#fff' }}>I'm your AI Marketing Bot, handling SMS blasts, promotions, and social chatter to keep your brand buzzing!</p>
                    </div>
                </div>
                <br />

                {/* <Promotion /> */}
                <br />
                <h3 className="bold-text text-black">Specials / Promotions</h3>
                <SpecialsManager />
                <br />
                <h3 className="bold-text text-black">SMS Campaigns</h3>
                <div style={{ display: 'flex' }} className="mb-2">
                    <a href="https://imac-staff.labthree.ai/sms-blast" target="_blank" className="mr-3">
                        <SimpleCard id="" running="" title="Send a single SMS blast" description="Send an SMS blast to all of your contacts whenever you want!">
                        </SimpleCard>
                    </a>
                    <a href="https://imac-staff.labthree.ai/sms-blast" target="_blank" className="">
                        <SimpleCard id="" running="" title="Maintain Blacklist" description="Add or remove any contacts to the blacklist so they dont get texted anymore for marketing campaigns!">
                        </SimpleCard>
                    </a>
                </div>
                <SimpleCard id="" running="" title="View my SMS history" description="Click here to view your SMS history and recent costs." expand={false}>
                    <SMSHistory />
                </SimpleCard>
                <br />
                <h3 className="bold-text text-black">Jobs</h3>
                <BlogWriter />
                <br />
                <CardDeck>
                    {/* <SimpleCard id="" running="Running 24/7" title="Replying to Instagram comments" description="Whenever someone comments on any of our Instagram posts, I'll reply." /> */}
                    <ContactManagement />
                </CardDeck>
                <br />

            </Container>
        </div>
    );
}

export default Marketing;
