import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';


const firebaseConfig = {
    apiKey: "AIzaSyDIWMqiBgKVPC9zpbPoR-q7bSURtAVdD0M",
    authDomain: "imac-medspa.firebaseapp.com",
    projectId: "imac-medspa",
    storageBucket: "imac-medspa.appspot.com",
    messagingSenderId: "209057704018",
    appId: "1:209057704018:web:495321765702b2b988d78b"
  };

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);

