import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Nav, NavItem, NavLink, Navbar, NavbarBrand } from 'reactstrap';
import './App.css';
import logo from './images/imac-logo.png'
import Reception from './components/Reception'
import Marketing from './components/Marketing'
import Sales from './components/Sales'
import Inventory from './components/Inventory'
import History from './components/History'
import ChronoLogo from './images/chrono-logo.svg';
import { db, auth } from "./firebase";
import axios from 'axios';
import { InfinitySpin } from "react-loader-spinner";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function App() {
  const [showReception, setShowReception] = useState(true);
  const [showMarketing, setShowMarketing] = useState(false);
  const [showSales, setShowSales] = useState(false);
  const [showInventory, setShowInventory] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [inputPassword, setInputPassword] = useState('');
  const [incorrectPassword, setIncorrectPassword] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [loading, setLoading] = useState(false);


  const handleClick = (type) => {
    setShowReception(type === 'Reception');
    setShowMarketing(type === 'Marketing');
    setShowSales(type === 'Sales');
    setShowInventory(type === 'Inventory');
    setShowHistory(type === 'History');
  };

  useEffect(() => {
    const tokenExpiry = localStorage.getItem('token_expiry');
    if (tokenExpiry && new Date().getTime() < Number(tokenExpiry)) {
      setAuthenticated(true);
    }
  }, []);

  const authenticate = async (password) => {
    setLoading(true);
    try {
      const response = await axios.post('https://imac-med-spa.labthree.ai/auth', { password });
      if (response.data.authenticated) {
        const fourDaysInMilliseconds = 4 * 24 * 60 * 60 * 1000;
        localStorage.setItem('token_expiry', (new Date().getTime() + fourDaysInMilliseconds).toString());
        setAuthenticated(true);
        setIncorrectPassword(false);
      } else {
        setIncorrectPassword(true);
        setShowErrorMsg(true);
        setTimeout(() => setShowErrorMsg(false), 2000);
      }
    } catch (error) {
      console.error('Auth failed:', error);
    }
    setLoading(false);
  };

  const inputStyle = incorrectPassword ? {
    animation: 'shake 0.2s',
    borderRadius: '10px ',
    padding: '10px',
    border: '1px solid red'
  } : {
    borderRadius: '10px ',
    border: '1px solid rgb(202, 202, 202)',
    padding: '10px'
  };

  if (!authenticated) {
    return (
      <>
        <div className="App">
          <Navbar expand="md" className="App-navbar">
            <NavbarBrand href="/">
              <img src={logo} alt="logo" className="App-logo" />
            </NavbarBrand>
          </Navbar>
          {
            loading ?
              <>
                <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh', flexDirection: 'column' }}>
                  <InfinitySpin
                    type="ThreeDots"
                    color="#00BFFF"
                    height={300}
                    width={300}
                  />
                  <p>Authenticating...</p>
                </Container>
              </> :
              <>
                <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh', flexDirection: 'column' }}>
                  <div>
                    <Card className="px-8 py-5 rounded-card">
                      <h2 className="bold-text text-black">Log in</h2>
                      <p className="text-black">Please enter your password to access the dashboard.</p>

                      <div>
                        <input
                          type="password"
                          placeholder="Password"
                          value={inputPassword}
                          onChange={(e) => setInputPassword(e.target.value)}
                          style={inputStyle}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              authenticate(inputPassword);
                            }
                          }}
                        />
                        <br />
                        <br />
                        <button
                          className="rounded-card"
                          onClick={() => authenticate(inputPassword)}
                        >
                          Submit
                        </button>
                        {showErrorMsg && <><br /><br /><p style={{ color: 'red', opacity: showErrorMsg ? 1 : 0 }}>Incorrect password. Please try again.</p></>}
                        <br /><br />

                      </div>
                    </Card>
                  </div>
                  <div className="text-center">
                    <a href="https://labthree.org/insights" target="_blank"><small className="text-muted">Powered by Lab3</small></a>
                  </div>
                </Container>
              </>
          }


        </div>
      </>
    )
  }

  return (
    <>
      <ToastContainer />
      <div className="App">
        <Navbar expand="md" className="App-navbar">
          <NavbarBrand href="/">
            <img src={logo} alt="logo" className="App-logo" />
          </NavbarBrand>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink href="#" onClick={() => handleClick('Reception')}>
                <span className={showReception ? 'bold-text rounded-card' : ''}>  Reception</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#" onClick={() => handleClick('Marketing')}>
                <span className={showMarketing ? 'bold-text rounded-card' : ''}>  Marketing</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#" onClick={() => handleClick('Sales')}>
                <span className={showSales ? 'bold-text rounded-card' : ''}>  Sales</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#" onClick={() => handleClick('Inventory')}>
                <span className={showInventory ? 'bold-text rounded-card' : ''}>  Inventory</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#" onClick={() => handleClick('History')}>
                <span className={showHistory ? 'bold-text rounded-card' : ''}>  History</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://billing.stripe.com/p/login/5kAeYo5y32dN6Hu144" target="_blank">
                <span>Billing</span>
              </NavLink>
            </NavItem>
          </Nav>
          <Nav className="ml-auto" navbar>
            <NavLink href="https://macmedspa.drchrono.com/appointments/" target="_blank">
              <small>Go to </small><br />
              <img style={{ width: '70%' }} src={ChronoLogo} alt="Chrono Logo" />
            </NavLink>
          </Nav>
        </Navbar>
        <Container>
          {showReception && <div><Reception /></div>}
          {showMarketing && <div><Marketing /></div>}
          {showSales && <div><Sales /></div>}
          {showInventory && <div><Inventory /></div>}
          {showHistory && <div><History /></div>}
        </Container>
      </div>
    </>

  );
}

export default App;