import React, { useState, useEffect, useMemo } from 'react';
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "@amir04lm26/react-modern-calendar-date-picker";
import { Table, Container, Row, Col, Card, CardDeck, CardBody, Nav, NavItem, NavLink, Navbar, Input, NavbarBrand, Button } from 'reactstrap';
import "../../App.css"
import axios from 'axios';
import moment from 'moment';
import { Oval } from 'react-loader-spinner'
import { toast } from 'react-toastify';


const ShiftScheduler = () => {
    const [selectedDay, setSelectedDay] = useState({
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate(),
    });

    const [selectedMonth, setSelectedMonth] = useState('01'); // Default to January

    const employees = useMemo(() => [
        { name: 'Candice', id: 1 },
        { name: 'Cassie', id: 2 },
        { name: 'Brianna G', id: 3 },
        { name: 'Jade', id: 4 },
        { name: 'Andrea', id: 5 }
    ], []);

    const fsemployees = useMemo(() => [
        { name: 'Jasmine', id: 8 },
        { name: 'Shannon', id: 9 },
        { name: 'Hailey', id: 10 }
    ], []);

    const [employeeData, setEmployeeData] = useState(
        employees.map(e => ({ id: e.id, name: e.name, shift_start: '', shift_end: '', off: false }))
    );
    const [fsemployeeData, setFSEmployeeData] = useState(
        fsemployees.map(e => ({ id: e.id, name: e.name, shift_start: '', shift_end: '', off: false }))
    );
    const [sendingData, setSendingData] = useState(false);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        const fetchShifts = async () => {
            setLoading(true);
            const dateStr = `${selectedDay.year}-${String(selectedDay.month).padStart(2, '0')}-${String(selectedDay.day).padStart(2, '0')}`;
            // console.log(dateStr);
            try {
                const response = await axios.get(`https://imac-med-spa.labthree.ai/get-shifts?date=${String(dateStr)}`);
                // console.log(response.data);  // Log the response body

                // Update employeeData based on the response
                const updatedEmployeeData = employees.map(employee => {
                    const serverData = response.data.find(item => item.id === employee.id);
                    return serverData ? {
                        ...employee,
                        shift_start: moment(serverData.shift_start, "h:mm A").format("HH:mm"),
                        shift_end: moment(serverData.shift_end, "h:mm A").format("HH:mm"),
                        off: serverData.off
                    } : {
                        ...employee,
                        shift_start: '',
                        shift_end: '',
                        off: false
                    };
                });

                setEmployeeData(updatedEmployeeData);

                setLoading(false);
            } catch (error) {
                console.error('Error fetching shifts:', error);
            }
        };

        const fetchFSShifts = async () => {
            setLoading(true);
            const dateStr = `${selectedDay.year}-${String(selectedDay.month).padStart(2, '0')}-${String(selectedDay.day).padStart(2, '0')}`;
            console.log(dateStr);
            try {
                const response = await axios.get(`https://imac-med-spa.labthree.ai/get-fsshifts?date=${String(dateStr)}`);
                console.log(response.data);

                // Assuming the 'id' of the employee is a string and matches the keys in the response data.
                // Also assuming 'fsemployees' contains an array of employees with an 'id' field.
                const updatedFSEmployeeData = fsemployees.map(fsemployee => {
                    // The server data for the current employee, referenced by their 'id'.
                    const serverData = response.data[fsemployee.id.toString()];
                    if (serverData) {
                        return {
                            ...fsemployee,
                            shift_start: moment(serverData.shift_start, "HH:mm").format("HH:mm"),
                            shift_end: moment(serverData.shift_end, "HH:mm").format("HH:mm"),
                            off: serverData.off
                        };
                    } else {
                        return {
                            ...fsemployee,
                            shift_start: '',
                            shift_end: '',
                            off: false
                        };
                    }
                });

                setFSEmployeeData(updatedFSEmployeeData);
            } catch (error) {
                console.error('Error fetching FS Shifts:', error);
                // Handle your error state
            } finally {
                setLoading(false);
            }
        };

        fetchShifts();
        fetchFSShifts();
    }, [selectedDay, employees, fsemployees]); // employees is now a memoized constant\


    // Handle month change
    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };

    const handleInputChange = (id, field, value) => {
        setEmployeeData(prevData =>
            prevData.map(e =>
                e.id === id ? { ...e, [field]: value } : e
            )
        );
    };

    const handleFSInputChange = (id, field, value) => {
        setFSEmployeeData(prevData =>
            prevData.map(e =>
                e.id === id ? { ...e, [field]: value } : e
            )
        );
    };

    const submitMonthlyData = async () => {
        const url = `https://imac-med-spa.labthree.ai/sendMonthlySchedule?year=2024&month=${selectedMonth}`;
        try {
            setLoading(true);
            toast.info("Sending monthly data...", { autoClose: false }); // Show an initial toast message

            const response = await axios.get(url); // Perform the GET request
            console.log('Server Response:', response.data); // Log the response data

            toast.dismiss(); // Dismiss the initial toast message
            toast.success("Monthly data sent successfully!"); // Show a success toast
        } catch (error) {
            console.error("Error submitting monthly data:", error); // Log any errors
            toast.dismiss(); // Ensure the initial toast is dismissed in case of an error
            toast.error("Failed to send monthly data."); // Show an error toast
        } finally {
            setLoading(false); // Ensure loading state is updated regardless of request outcome
        }
    };



    const saveData = async () => {
        const date = `${selectedDay.year}-${String(selectedDay.month).padStart(2, '0')}-${String(selectedDay.day).padStart(2, '0')}`;

        const filteredRequestBody = employeeData.filter(e =>
            !(e.shift_start === '' && e.shift_end === '' && !e.off)
        ).map(e => ({
            date: date,
            id: e.id,
            shift_start: e.shift_start,
            shift_end: e.shift_end,
            off: e.off
        }));

        console.log("Filtered Request Body:", filteredRequestBody);

        try {
            toast.info("Sending data...", { autoClose: false });
            const response = await axios.post(`https://imac-med-spa.labthree.ai/set-schedule?date=${date}`, filteredRequestBody);
            console.log('Server Response:', response.data);
            toast.dismiss();
            toast.success("Data saved successfully!");
        } catch (error) {
            console.error('Something went wrong:', error);
            toast.dismiss();
            toast.error("Failed to save data.");
        }

    };

    const handleSubmitWithConfirmation = () => {
        const isConfirmed = window.confirm("Are you sure you want to submit for the selected month?");
        if (isConfirmed) {
            submitMonthlyData(); // Call your submit function only if confirmed
        }
    };


    const saveFSData = async () => {
        const date = `${selectedDay.year}-${String(selectedDay.month).padStart(2, '0')}-${String(selectedDay.day).padStart(2, '0')}`;

        const shifts = fsemployeeData.map(e => ({
            id: e.id,
            name: e.name, // Retain the name field here
            shift_start: e.shift_start,
            shift_end: e.shift_end,
            off: e.off
        }));

        console.log("Filtered Request Body:", shifts);

        try {
            toast.info("Sending data...", { autoClose: false });
            // Send the entire array of shifts in a single request
            const response = await axios.post(`https://imac-med-spa.labthree.ai/saveShift`, {
                shiftData: shifts, // Send the array of shifts
                date: date
            });
            console.log('Server Response:', response.data);
            toast.dismiss();
            toast.success("Data saved successfully!");
        } catch (error) {
            console.error('Something went wrong:', error);
            toast.dismiss();
            toast.error("Failed to save data.");
        }
    };


    const getDisabledDays = (startYear, endYear) => {
        let disabledDays = [];
        for (let year = startYear; year <= endYear; year++) {
            for (let month = 1; month <= 12; month++) {
                let daysInMonth = new Date(year, month, 0).getDate();
                for (let day = 1; day <= daysInMonth; day++) {
                    let weekDay = new Date(year, month - 1, day).getDay();
                    if (weekDay === 0 || weekDay === 1) { // 0 for Sunday, 1 for Monday
                        disabledDays.push({ year: year, month: month, day: day });
                    }
                }
            }
        }
        return disabledDays;
    };

    const disabledDays = getDisabledDays(selectedDay.year, selectedDay.year);

    return (
        <div style={{ display: 'flex' }}>
            <div>
                <Calendar
                    value={selectedDay}
                    onChange={setSelectedDay}
                    colorPrimary="#0fbcf9"
                    disabledDays={disabledDays}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Card
                    body
                    className={`rounded-card ml-4 ${loading ? 'd-flex justify-content-center align-items-center' : ''}`}
                    style={loading ? { flexDirection: 'column' } : {}}
                >
                    {
                        loading ?
                            <>
                                Loading...
                                <Oval

                                    height={80}
                                    width={80}
                                    color="#13CFEB"
                                    wrapperStyle={{}}
                                    wrapperClass="fastOval"
                                    visible={true}
                                    ariaLabel='oval-loading'
                                    secondaryColor="#FFF4EE"
                                    strokeWidth={2}
                                    strokeWidthSecondary={2}
                                />
                            </> :
                            <>
                                <table>
                                    <thead className="font-weight-bold">
                                        Nurses
                                        <tr>
                                            <td></td>
                                            <td><small>Shift Start</small></td>
                                            <td><small>Shift End</small></td>
                                            <td className="text-center"><small>OFF</small></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {employeeData.map(e => (
                                            <tr key={e.id}>
                                                <td><small>{e.name}</small></td>
                                                <td>
                                                    <Input
                                                        style={{ width: '100%' }}
                                                        type="time"
                                                        value={e.shift_start ? moment(e.shift_start, "HH:mm").format("HH:mm") : ""}
                                                        disabled={e.off}
                                                        onChange={(event) => handleInputChange(e.id, 'shift_start', event.target.value)}
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        style={{ width: '100%' }}
                                                        type="time"
                                                        value={e.shift_end ? moment(e.shift_end, "HH:mm").format("HH:mm") : ""}
                                                        disabled={e.off}
                                                        onChange={(event) => handleInputChange(e.id, 'shift_end', event.target.value)}
                                                    />
                                                </td>
                                                <td className="text-center">
                                                    <input
                                                        type="checkbox"
                                                        style={{ width: '20px', height: '20px' }}
                                                        checked={e.off}
                                                        onChange={(event) => handleInputChange(e.id, 'off', event.target.checked)}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {/* <Button className="rounded-card my-2" onClick={saveData}>
                                    Save
                                </Button> */}
                            </>
                    }

                </Card>
                <Card
                    body
                    className={`rounded-card ml-4 ${loading ? 'd-flex justify-content-center align-items-center' : ''}`}
                    style={loading ? { flexDirection: 'column' } : {}}
                >
                    {
                        loading ?
                            <>
                                Loading...
                                <Oval

                                    height={40}
                                    width={40}
                                    color="#13CFEB"
                                    wrapperStyle={{}}
                                    wrapperClass="fastOval"
                                    visible={true}
                                    ariaLabel='oval-loading'
                                    secondaryColor="#FFF4EE"
                                    strokeWidth={2}
                                    strokeWidthSecondary={2}
                                />
                            </> :
                            <>
                                <table>
                                    <thead className="font-weight-bold">
                                        <medium>Front Desk</medium>
                                        <tr>
                                            <td></td>
                                            <td><small>Shift Start</small></td>
                                            <td><small>Shift End</small></td>
                                            <td className="text-center"><small>OFF</small></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fsemployeeData.map(e => (
                                            <tr key={e.id}>
                                                <td><small>{e.name}</small></td>
                                                <td>
                                                    <Input
                                                        style={{ width: '100%' }}
                                                        type="time"
                                                        value={e.shift_start ? moment(e.shift_start, "HH:mm").format("HH:mm") : ""}
                                                        disabled={e.off}
                                                        onChange={(event) => handleFSInputChange(e.id, 'shift_start', event.target.value)}
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        style={{ width: '100%' }}
                                                        type="time"
                                                        value={e.shift_end ? moment(e.shift_end, "HH:mm").format("HH:mm") : ""}
                                                        disabled={e.off}
                                                        onChange={(event) => handleFSInputChange(e.id, 'shift_end', event.target.value)}
                                                    />
                                                </td>
                                                <td className="text-center">
                                                    <input
                                                        type="checkbox"
                                                        style={{ width: '20px', height: '20px' }}
                                                        checked={e.off}
                                                        onChange={(event) => handleFSInputChange(e.id, 'off', event.target.checked)}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {/* Month Selection and Submit Button */}
                                <br></br>


                                <Button className="rounded-card my-2 mt-auto" onClick={() => { saveData(); saveFSData() }}>
                                    Save
                                </Button>
                                <div className="d-flex justify-content-between" style={{ marginBottom: '1rem' }}> {/* Adjust the marginBottom value as needed */}
                                    <select value={selectedMonth} onChange={handleMonthChange} className="form-control w-auto" > {/* Adjust marginRight to control spacing between dropdown and button */}
                                        {Array.from({ length: 12 }, (_, i) => (
                                            <option key={i} value={String(i + 1).padStart(2, '0')}>
                                                {new Date(0, i).toLocaleString('default', { month: 'long' })}
                                            </option>
                                        ))}
                                    </select>
                                    <button className="rounded-card btn btn-primary btn-sm" onClick={handleSubmitWithConfirmation}>
                                        Text Schedules
                                        {/* for {new Date(2024, selectedMonth - 1).toLocaleString('default', { month: 'long' })} */}
                                    </button>
                                </div>
                            </>
                    }

                </Card>
            </div>
        </div>
    );
};


export default ShiftScheduler;


