import React, { useState, useEffect, handleItemChange, removeItem, addItem, add } from 'react';
import { Modal, Navbar, Container, Nav, NavItem, NavLink, Card, CardDeck, Table, Row, Col, Form, FormGroup, Label, Input, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, } from 'recharts';
// import Modal from 'react-modal';  // You can use your own modal package if you prefer
import { db } from '../firebase'; // Path to your firebase.js file
import { collection, addDoc, deleteDoc, doc, updateDoc, query, getDocs, setDoc, where, getDoc, orderBy } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';  // Import the UUID library to generate unique IDs
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import '../App.css';
import purpleBot from '../images/purple-bot.png';
import trashImage from '../images/trash.png';
import axios from 'axios';
import { Oval } from 'react-loader-spinner'


//inventory test data and graph code

const data = [
  { name: 'Botox', value: 12 },
  { name: 'Morpheus', value: 15 },
  { name: 'Enzyme Gel', value: 33 },
  { name: 'Face Peels', value: 17 },
];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <h4 className="bold-text text-black label">{`$${payload[0].value}`}</h4>
      </div>
    );
  }
  return null;
};

const MyBarChart = () => (
  <ResponsiveContainer width="100%" height={300}>
    <BarChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip content={<CustomTooltip />} />
      <Bar dataKey="value" fill="#8884d8" />
    </BarChart>
  </ResponsiveContainer>
)

const aggregateByBox = (dataList) => {
  return dataList.reduce((acc, currentItem) => {
    // Ensure units is a number
    const currentUnits = Number(currentItem.units);

    // If the box already exists in the accumulator, add the units
    if (acc[currentItem.box]) {
      acc[currentItem.box].units += currentUnits;
    } else {
      // Otherwise, initialize the box with the current item's data
      acc[currentItem.box] = {
        item: currentItem.item,
        box: currentItem.box,
        units: currentUnits
      };
    }
    return acc;
  }, {});
};

//log grid code

const MyLGrid = ({ headers, removeRow }) => {

  const [editableCell, setEditableCell] = useState(null);
  const [dataList, setDataList] = useState([]);
  const [searchBoxTerm, setSearchBoxTerm] = useState('');


  const handleCellClick = (row, col) => {
    setEditableCell({ row, col });
  };

  const handleCellBlur = () => {
    setEditableCell(null);
  };

  const handlePONumberClick = (poNumber) => {
    console.log(`PO Number ${poNumber} clicked`);
  };

  useEffect(() => {
    const fetchData = async () => {
      const dataCollectionRef = collection(db, "Inventory", "Pending", "PendingNurse");
      const querySnapshot = await getDocs(dataCollectionRef);
      const fetchedDataList = querySnapshot.docs.map(doc => doc.data());
      const aggregatedData = aggregateByBox(fetchedDataList);

      // Convert object back to array for easier mapping
      const aggregatedArray = Object.values(aggregatedData);
      setDataList(aggregatedArray);
    };

    fetchData();
  }, []);

  const filteredDataList = searchBoxTerm
    ? dataList.filter(data =>
      String(data.box).includes(searchBoxTerm) ||
      String(data.item).toLowerCase().includes(searchBoxTerm.toLowerCase())
    )
    : dataList;


  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
        <h3 className='bold-text text-black'>Logs</h3>
        <Input
          className='rounded-card'
          type="search"
          placeholder="Search..."
          value={searchBoxTerm}
          onChange={e => setSearchBoxTerm(e.target.value)}
          style={{ width: '250px' }} // Setting width for the search bar
        />
      </div>
      <div className="table-responsive">
        <table className="table table-hover">
          <thead className="scrollable-thead">
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
              <th style={{ width: '50px' }}></th>
            </tr>
          </thead>
          <tbody className="scrollable-tbody">
            {filteredDataList.map((dataItem, i) => (
              <tr key={i} className={i % 2 === 0 ? 'even-row' : 'odd-row'}>
                {headers.map((header, j) => (
                  <td key={j} onClick={() => handleCellClick(i, j)}>
                    {editableCell && editableCell.row === i && editableCell.col === j ? (
                      <input
                        type="text"
                        defaultValue={dataItem[header.toLowerCase()]}
                        onBlur={handleCellBlur}
                        autoFocus
                        style={{ width: '100%' }}
                      />
                    ) : dataItem[header.toLowerCase()]}
                  </td>
                ))}
                <td style={{ width: '50px' }}>
                  <img
                    src={trashImage}
                    alt="Trash Can"
                    style={{ width: '100%', height: 'auto' }}
                    onClick={() => removeRow(i)}
                  />
                </td>
              </tr>
            ))}
          </tbody>

        </table>
      </div>
    </div>
  );
};











//boiler plate grid code

const MyGrid = ({ headers, rowCount, removeRow }) => {

  const [editableCell, setEditableCell] = useState(null); // Store the editable cell coordinates {row, col}

  const handleCellClick = (row, col) => {
    setEditableCell({ row, col });
  };

  const handleCellBlur = () => {
    setEditableCell(null);
  };

  const handlePONumberClick = (poNumber) => {
    // Handle click event on the PO Number
    // This can be where you open the modal to view details, or navigate to a new page
    console.log(`PO Number ${poNumber} clicked`);
  };

  return (
    <div>
      <div className="table-responsive">
        <table className="table table-hover">
          <thead className="scrollable-thead">
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
              <th style={{ width: '50px' }}></th>
            </tr>
          </thead>
          <tbody className="scrollable-tbody">
            {Array.from({ length: rowCount })
              .map((_, i) => i)
              .reverse()
              .map((i) => (
                <tr key={i} className={i % 2 === 0 ? 'even-row' : 'odd-row'}>
                  {headers.map((_, j) => (
                    <td key={j} onClick={() => handleCellClick(i, j)}>
                      {editableCell && editableCell.row === i && editableCell.col === j ? (
                        <input
                          type="text"
                          defaultValue="Data"
                          onBlur={handleCellBlur}
                          autoFocus
                          style={{ width: '100%' }}  // Fill the parent cell
                        />
                      ) : (
                        j === 2 ? <a href="#" onClick={() => handlePONumberClick('Data')}>Data</a> : "Data"
                      )}
                    </td>
                  ))}
                  <td style={{ width: '50px' }}>
                    <img
                      src={trashImage}
                      alt="Trash Can"
                      style={{ width: '100%', height: 'auto' }}
                      onClick={() => removeRow(i)}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};





const FIELD_MAPPINGS = {
  'orderDate': 'Order Date',
  'vendor': 'Vendor',
  'poNumber': 'PO Number',
  'totalQuantity': 'Qty Ordered',
  'totalCost': 'Total Amount',
  // Add any other fields as needed
};


const MyOGrid = ({ headers, rowCount, removeRow }) => {

  const [editableCell, setEditableCell] = useState(null); // Store the editable cell coordinates {row, col}
  const [modal, setModal] = useState(false);
  const [docData, setDocData] = useState(null);

  const handleCellClick = (row, col) => {
    setEditableCell({ row, col });
  };

  const handleCellBlur = () => {
    setEditableCell(null);
  };

  const handleDelete = async (index) => {
    const poToDelete = poList[index];
    const poDocRef = doc(db, "Inventory", "Pending", "Pending List", poToDelete.id);
    await deleteDoc(poDocRef);
    const updatedList = [...poList];
    updatedList.splice(index, 1);
    setPoList(updatedList);
  };

  const handleUpdatePO = async () => {
    try {
      const poCollectionRef = collection(db, "Inventory", "Pending", "Pending List");
      const poDocRef = doc(poCollectionRef, docData.id);

      await updateDoc(poDocRef, {
        items: docData.items,
      });

      setModal(false);
      alert('PO updated successfully');
    } catch (error) {
      console.error('Error updating PO: ', error);
    }
  };

  const handleItemChange = (index, field, value) => {
    // Clone the items array
    const newItems = [...docData.items];

    // Update the item at the given index
    newItems[index] = {
      ...newItems[index],
      [field]: value
    };

    // Update the state
    setDocData({
      ...docData,
      items: newItems
    });
  };



  const handlePONumberClick = async (poNumber) => {
    // Find the document ID associated with the clicked PO number
    const docInfo = poNumbers.find(info => info.poNumber === poNumber);

    if (docInfo) {
      const docId = docInfo.id;

      // Fetch the document data from Firestore
      const poCollectionRef = collection(db, "Inventory", "Pending", "Pending List");
      const docRef = doc(poCollectionRef, docId);

      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        setDocData(docSnapshot.data());
        setModal(true);
      } else {
        console.log(`No document found for PO Number ${poNumber}`);
      }
    } else {
      console.log(`No document ID found for PO Number ${poNumber}`);
    }
  };

  const [poList, setPoList] = useState([]);
  // const [poList2, setPoList2] = useState([]);

  //fetch from firebase for all Pending POs

  useEffect(() => {
    const fetchPOList = async () => {
      const poCollectionRef = collection(db, "Inventory", "Pending", "Pending List");

      const querySnapshot = await getDocs(poCollectionRef);

      // Filtering out the documents where 'completed' is true
      const dataList = querySnapshot.docs
        .filter(doc => doc.data().completed !== true)
        .map(doc => ({
          ...doc.data(),
          id: doc.id
        }));

        dataList.sort((a, b) => {
          const dateA = new Date(a.orderDate);
          const dateB = new Date(b.orderDate);
          return dateB - dateA; // For descending order
        });

      setPoList(dataList);
    };

    fetchPOList();
  }, []);


  const [poNumbers, setPoNumbers] = useState([]);

  useEffect(() => {
    const fetchPONumbers = async () => {
      const poCollectionRef = collection(db, "Inventory", "Pending", "Pending List");

      const querySnapshot = await getDocs(poCollectionRef);

      // Extracting the 'id' and 'poNumber' from each document
      const poNumberList = querySnapshot.docs
        .filter(doc => doc.data().completed !== true)
        .map(doc => ({
          id: doc.id,
          poNumber: doc.data().poNumber
        }));

      setPoNumbers(poNumberList);
    };

    fetchPONumbers();
  }, []);

  // useEffect(() => {
  //   const fetchPOList2 = async () => {
  //     const poCollectionRef = collection(db, "Inventory", "Pending", "Pending List");

  //     const querySnapshot = await getDocs(poCollectionRef);

  //     // Filtering out the documents where 'completed' is true
  //     const dataList = querySnapshot.docs
  //       .filter(doc => doc.data().completed !== false)
  //       .map(doc => ({
  //         ...doc.data(),
  //         id: doc.id
  //       }));

  //     setPoList2(dataList);
  //   };

  //   fetchPOList2();
  // }, []);



  return (
    <div>
      <div className="table-responsive">
        <table className="table table-hover">
          <thead className="scrollable-thead">
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
              <th style={{ width: '50px' }}></th>
            </tr>
          </thead>
          <tbody className="scrollable-tbody">
            {poList.map((po, i) => (
              <tr key={po.id} className={i % 2 === 0 ? 'even-row' : 'odd-row'}>
                {Object.keys(FIELD_MAPPINGS).map((fieldKey, j) => {
                  const header = FIELD_MAPPINGS[fieldKey];
                  return (
                    <td
                      key={j}
                      onClick={() => {
                        if (fieldKey === 'poNumber') {
                          // handlePONumberClick(po[fieldKey]);
                        } else {
                          handleCellClick(i, j);
                        }
                      }}
                    >
                      {
                        fieldKey == 'poNumber' ?
                          <>
                            <a href="#" onClick={() => handlePONumberClick(po[fieldKey])}>
                              {po[fieldKey]}
                            </a>
                          </> :
                          <>
                            {po[fieldKey]}
                          </>
                      }
                    </td>
                  );
                })}
                <td style={{ width: '50px' }}>
                  <img
                    src={trashImage}
                    alt="Trash Can"
                    style={{ width: '100%', height: 'auto' }}
                    onClick={() => handleDelete(i)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Modal isOpen={modal} toggle={() => setModal(!modal)} centered style={{ width: '50%' }}>
        <Card className="rounded-card" style={{ backgroundColor: '#ffedf0' }}>
          <div className="text-center">
            <h3 className="text-black bold-text">
              Pending Order Details
            </h3>
          </div>
          {
            docData && <>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <small className="text-black"><span className="font-weight-bold">Vendor: </span>{docData.vendor}<br/> </small>
                  <small className="text-black"><span className="font-weight-bold">Order Date: </span>{docData.orderDate}<br/> </small>
                  <small className="text-black"><span className="font-weight-bold">Payment: </span>${Number(docData.totalCost).toFixed(2)}<br/> </small>
                  <small className="text-black"><span className="font-weight-bold">Total Item Count: </span>{docData.totalQuantity}<br/> </small>
                </div>
                <div className="text-right text-black">
                  <small>Purchase Order</small>
                  <h3 className="text-black bold-text">{docData.poNumber}</h3>
                </div>
              </div>
              <br/>
            </>
          }

          {docData && docData.items.map((item, index) => (
            <div key={index} className="form-group">
              <h5 className="text-black bold-text">Item {index + 1}</h5>
              <label className="mx-2 text-black">
                Product:
                <input
                  type="text"
                  value={item.name}
                  onChange={e => handleItemChange(index, 'product', e.target.value)}
                  className="form-control"
                />
              </label>
              <label className="mx-2 text-black">
                Quantity:
                <input
                  type="number"
                  value={item.quantity}
                  onChange={e => handleItemChange(index, 'quantity', parseFloat(e.target.value))}
                  className="form-control"
                />
              </label>
              <label className="mx-2 text-black">
                Price:
                <input
                  type="number"
                  value={item.price}
                  onChange={e => handleItemChange(index, 'price', parseFloat(e.target.value))}
                  className="form-control"
                />
              </label>
              <label className="mx-2 text-black">
                Tax:
                <select
                  value={item.taxOption}
                  onChange={e => handleItemChange(index, 'taxOption', e.target.value)}
                  className="form-control"
                >
                  <option value="withoutTax">Without Tax</option>
                  <option value="withTax">With Tax</option>
                </select>
              </label>
            </div>
          ))}
          <div className="text-center">
            <Button color="primary" onClick={handleUpdatePO}>Update PO</Button>
            <Button color="secondary" onClick={() => setModal(!modal)}>Close</Button>
          </div>
        </Card>
      </Modal>
    </div >
  );
}



//products grid

const MyCGrid = ({ headers, rowCount, removeRow, products }) => {
  const [editableCell, setEditableCell] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');


  const handleCellBlur = (product, header, newValue) => {
    setEditableCell(null);
    const updatedProduct = { ...product, [header]: newValue };
    updateProductInFirestore(updatedProduct)
      .then(() => {
        // Update the local state here
        const updatedProducts = localProducts.map(p =>
          p.id === updatedProduct.id ? updatedProduct : p
        );
        setLocalProducts(updatedProducts);
      })
      .catch(error => {
        console.error("Error updating document: ", error);
      });
  };


  useEffect(() => {
    // Define the callback for the document click event
    const handleOutsideClick = () => {
      setEditableCell(null);
    };

    // Add the event listener when the component mounts
    document.addEventListener('click', handleOutsideClick);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const handleCellClick = (event, row, col) => {
    event.stopPropagation(); // Prevent click event from bubbling up to the document
    setEditableCell({ row, col });
  };



  const updateProductInFirestore = async (updatedProduct) => {
    try {
      console.log("Updating Firestore with:", updatedProduct);  // Debug log

      const productDoc = doc(db, 'Inventory', 'Main Inventory', 'Products', updatedProduct.id);
      await updateDoc(productDoc, updatedProduct);

      // Use the products prop to get the current list of products
      const currentProducts = products;

      // Check if the product already exists in the list
      const productIndex = currentProducts.findIndex(p => p.id === updatedProduct.id);

      if (productIndex !== -1) {
        // If the product exists, update it
        currentProducts[productIndex] = updatedProduct;
      } else {
        // If the product doesn't exist, add it
        currentProducts.push(updatedProduct);
      }

      // Update local state here after Firestore update is successful
      setLocalProducts(currentProducts);

      return true;  // Successfully updated Firestore
    } catch (error) {
      console.error("Error updating document: ", error);
      return false;  // Failed to update Firestore
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.target.blur();
    }
  };


  const [localProducts, setLocalProducts] = useState(products); // new state variable

  useEffect(() => {
    if (localProducts.length === 0) {
      setLocalProducts(products); // Sync local copy with prop changes
    }
  }, [products]);

  async function deleteProduct(id) {
    console.log("Attempting to delete product with id:", id);
    try {
      // Remove the deleted item from the local copy of products
      const updatedProducts = localProducts.filter(product => product.id !== id);
      setLocalProducts(updatedProducts);  // Update the local state

      await deleteDoc(doc(db, "Inventory", "Main Inventory", "Products", id));
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  }
  const hasOrderDate = headers.includes("Order Date");

  const sortedProducts = hasOrderDate
    ? [...localProducts].sort((a, b) => new Date(b["Order Date"]) - new Date(a["Order Date"]))
    : localProducts;

  const filteredProducts = searchTerm
    ? sortedProducts.filter(product =>
      (product.Name && product.Name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (product.Vendor && product.Vendor.toLowerCase().includes(searchTerm.toLowerCase()))
    )
    : sortedProducts;

  return (
    <div className="my-grid-wrapper">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
        <h3 className='bold-text text-black'>Products</h3>


        <Input
          className='rounded-card'
          type="search"
          placeholder="Search..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          style={{ width: '250px' }} // Adjust width for the search bar
        />
      </div>
      <div className="my-table-responsive">
        <table className="my-table">
          <thead className="my-table-header">
            <tr>
              {headers.map((header, index) => (
                <th className="my-table-th" key={index}>
                  {header}
                </th>
              ))}
              <th className="my-table-th">Actions</th> {/* Add this line */}
            </tr>
          </thead>

          <tbody className="my-table-body scrollable-tbody">
            {filteredProducts.map((product, rowIndex) => (
              <tr
                key={rowIndex}
                className={rowIndex % 2 === 0 ? 'even-row' : 'odd-row'}
              >
                {headers.map((header, colIndex) => (
                  <td
                    className="my-table-td"
                    key={colIndex}
                    onClick={(event) => handleCellClick(event, rowIndex, colIndex)}
                  >
                    {editableCell && editableCell.row === rowIndex && editableCell.col === colIndex ? (
                      <input
                        className="my-table-input"
                        type="text"
                        defaultValue={product[header]}
                        onBlur={(e) => handleCellBlur(product, header, e.target.value)}
                        onKeyDown={handleKeyDown}  // Add this line
                      />
                    ) : (
                      product[header]
                    )}
                  </td>
                ))}
                <td className="my-table-td">
                  <button style={{ border: 'none', background: 'none' }} onClick={() => {
                    console.log("Button clicked, product.id is:", product.id); // Add this line
                    deleteProduct(product.id);
                  }}>
                    <img src={trashImage} alt="Delete" style={{ width: '20px', height: 'auto' }} />
                  </button>


                </td>

              </tr>
            ))}
          </tbody>

        </table>
      </div>
    </div>
  );


};



function SimpleCard({ running, title, description }) {
  const [expanded, setExpanded] = useState(false); // State to toggle the description

  return (
    <>
      <Card body className="rounded-card">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ left: '10px', display: 'flex', alignItems: 'center' }}>
            <div style={{ width: '10px', height: '10px', backgroundColor: '#90EE90', borderRadius: '50%', animation: 'pulsate 2s infinite' }}></div>
            <span style={{ fontSize: '15px', marginLeft: '5px' }}>{running}</span>
          </div>

          {/* Add the settings button here */}
          <button style={{ background: 'transparent', border: 'none' }} onClick={() => {/* Add your function to handle settings click here */ }}>
            ⚙️ {/* Replace with the actual icon you'd like to use */}
          </button>

        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <h5 type="button" onClick={() => { setExpanded(!expanded) }} style={{ textAlign: 'left' }} className="bold-text text-black">{title}</h5>
            <p style={{ textAlign: 'left' }}>{description}</p>
          </div>
        </div>
      </Card>
    </>
  )
}



export const CreatePOForm = ({ closeModal }) => {
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState('');
  const [items, setItems] = useState([]);
  const [taxOption, setTaxOption] = useState('withoutTax');
  const [suggestedItems, setSuggestedItems] = useState([]);
  const [useInputFields, setUseInputFields] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');
  const [selectedItemCost, setSelectedItemCost] = useState('');


  const handleItemInputChange = (e) => {
    handleCurrentItemChange('name', e.target.value);
    const value = e.target.value;

    // Check if item exists before trying to convert it to lowercase
    const filtered = vendorItems.filter(item => item && item.toLowerCase().startsWith(value.toLowerCase()));

    setSuggestedItems(filtered);
  };

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        console.log("Fetching vendors...");

        const mainInventoryDocRef = doc(db, "Inventory", "Main Inventory");
        const productsCollectionRef = collection(mainInventoryDocRef, "Products");

        console.log("References set up...");

        const productsSnapshot = await getDocs(productsCollectionRef);
        console.log("Data fetched from Firestore:", productsSnapshot.docs);

        const allVendors = productsSnapshot.docs.map(doc => doc.data().Vendor);
        const uniqueVendors = [...new Set(allVendors)];

        console.log("Unique vendors:", uniqueVendors);
        setVendors(uniqueVendors);
      } catch (error) {
        console.error("Error fetching vendors:", error);
      }
    };


    fetchVendors();
  }, []);

  const [vendorItems, setVendorItems] = useState([]);

  useEffect(() => {
    if (selectedItem) {
      const fetchCostForItem = async () => {
        try {
          const mainInventoryDocRef = doc(db, "Inventory", "Main Inventory");
          const productsCollectionRef = collection(mainInventoryDocRef, "Products");

          // Querying products based on the selected item
          const itemQuery = query(productsCollectionRef, where("Name", "==", selectedItem));
          const querySnapshot = await getDocs(itemQuery);

          // Extracting cost from the fetched document
          if (!querySnapshot.empty) {
            const itemData = querySnapshot.docs[0].data();
            console.log("Item data:", itemData);
            setSelectedItemCost(itemData.Cost);
          } else {
            console.log(`No document found for item: ${selectedItem}`);
          }
        } catch (error) {
          console.error("Error fetching item cost:", error);
        }
      };

      fetchCostForItem();
    }
  }, [selectedItem]);

  useEffect(() => {
    if (selectedVendor) {
      const fetchItemsForVendor = async () => {
        try {
          const mainInventoryDocRef = doc(db, "Inventory", "Main Inventory");
          const productsCollectionRef = collection(mainInventoryDocRef, "Products");

          // Querying products based on the selected vendor
          const vendorQuery = query(productsCollectionRef, where("Vendor", "==", selectedVendor));
          const querySnapshot = await getDocs(vendorQuery);

          // Extracting names from the fetched documents
          console.log("Sample document:", querySnapshot.docs[0].data());
          const vendorSpecificItems = querySnapshot.docs.map(doc => doc.data().Name);

          setVendorItems(vendorSpecificItems);
          console.log(vendorSpecificItems);
        } catch (error) {
          console.error("Error fetching items:", error);
        }
      };

      fetchItemsForVendor();
    }
  }, [selectedVendor]);

  const [currentItem, setCurrentItem] = useState({ name: '', quantity: 0, price: selectedItemCost, taxOption: 'withoutTax' });


  const generatePONumber = () => {
    return Date.now().toString();
  };

  const calculateTotalQuantity = items => {
    return items.reduce((acc, item) => acc + item.quantity, 0);
  };

  const calculateTotalCost = items => {
    return items.reduce((acc, item) => acc + item.price * item.quantity, 0);
  };

  const handleAddItem = () => {
    if (currentItem.name && currentItem.quantity) {
      currentItem.price = selectedItemCost;
      setItems([...items, currentItem]);
      setCurrentItem({ name: '', quantity: 0, price: selectedItemCost, taxOption: 'withoutTax' });  // Reset current item after adding
    } else {
      alert('Please fill in all fields before adding an item');
    }
  };

  const handleCurrentItemChange = (field, value) => {
    setCurrentItem(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  const removeItem = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
  }

  function getCurrentDateFormatted() {
    const date = new Date();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const month = monthNames[date.getMonth()];
    const day = date.getDate().toString().padStart(2, '0');  // Add leading zero if needed
    const year = date.getFullYear().toString().slice(2);     // Get the last two digits of the year

    return `${month} ${day}, ${year}`;
  }

  const handleCreatePO = async () => {
    const poNumber = generatePONumber();
    const orderDate = getCurrentDateFormatted(); // Get the current date/time in ISO format
    const totalQuantity = calculateTotalQuantity(items);
    const totalCost = calculateTotalCost(items);

    // Generate a unique ID for each item using UUID
    const itemsWithUniqueIds = items.map(item => ({
      ...item,
      id: uuidv4()  // Assign a unique ID to each item
    }));

    const poData = {
      orderDate,
      vendor: selectedVendor,
      poNumber,
      totalQuantity,
      totalCost,
      items: itemsWithUniqueIds,   // Use the updated items list with unique IDs
    };

    try {
      const poCollectionRef = collection(db, "Inventory", "Pending", "Pending List");
      console.log(poData)
      const docRef = await addDoc(poCollectionRef, poData);
      console.log("Document written with ID: ", docRef.id);
      closeModal();
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Card
        body
        className="rounded-card"
        style={{
          backgroundColor: "#fff4ee",
          maxWidth: '80%',
          width: 'auto'
        }}>
        <h3 className="text-black bold-text">Create New Purchase Order</h3>
        <label>
          <input
            type="checkbox"
            checked={useInputFields}
            onChange={() => setUseInputFields(!useInputFields)}
          />
          New Vendor<br></br>
        </label>

        <Row>
          <Col>
            {
              useInputFields ? (
                <>
                  <label className="poForm_selectLabel">
                    Vendor:
                    <input
                      type="text"
                      value={selectedVendor}
                      onChange={e => setSelectedVendor(e.target.value)}
                      placeholder="Enter Vendor"
                      className="poForm_input rounded-card"
                    />
                  </label>
                  <label className="poForm_selectLabel mx-2">
                    Product:
                    <input
                      type="text"
                      value={currentItem.name}
                      onChange={e => handleCurrentItemChange('name', e.target.value)}
                      placeholder="Enter Product"
                      className="poForm_input rounded-card"
                    />
                  </label>
                </>
              ) : (
                <>
                  <label className="poForm_selectLabel">
                    Vendor:
                    <select
                      value={selectedVendor}
                      onChange={e => setSelectedVendor(e.target.value)}
                      className="poForm_select rounded-card"
                    >
                      {vendors.map(vendor => <option key={vendor} value={vendor}>{vendor}</option>)}
                    </select>
                  </label>
                  <label className="poForm_selectLabel mx-2">
                    Product:
                    <select
                      value={currentItem.name}
                      onChange={e => {
                        handleCurrentItemChange('name', e.target.value);
                        setSelectedItem(e.target.value);
                      }}
                      className="poForm_select rounded-card"
                    >
                      <option value="">Select a product</option>
                      {vendorItems.map((item, index) => (
                        <option key={index} value={item}>{item}</option>
                      ))}
                    </select>
                  </label>
                </>
              )
            }

            <br />
            <small className="text-black">Quantity:</small>
            <Input
              type="number"
              value={currentItem.quantity}
              onChange={e => handleCurrentItemChange('quantity', parseFloat(e.target.value))}
              placeholder="Quantity"
              className="poForm_input rounded-card"
            />
            <small className="text-black">Price:</small>
            <Input
              type="number"
              value={selectedItemCost}
              onChange={e => handleCurrentItemChange('price', parseFloat(e.target.value))}
              placeholder="Price"
              className="poForm_input rounded-card"
            />

            <small className="text-black">Tax:</small>
            <Input type="select"
              value={currentItem.taxOption}
              onChange={e => handleCurrentItemChange('taxOption', e.target.value)}
              className="poForm_select rounded-card"
            >
              <option value="withoutTax">Without Tax</option>
              <option value="withTax">With Tax</option>
            </Input>
            <button onClick={handleAddItem} className="rounded-card">
              Add Item
            </button>
          </Col>

          <Col >
            <Card className="rounded-card" body style={{ backgroundColor: "#fff4ee" }}>

              <p className="text-black font-weight-bold">Items Added:</p>
              <ul className="poForm_itemList">
                {items.map((item, index) => (
                  <li key={index} className="poForm_item text-black">
                    <span>{item.name}</span>
                    <span>{item.quantity}</span>
                    <span>{'$' + Number(item.price).toFixed(2)}</span>
                    <span>{item.taxOption}</span>
                    <img
                      src={trashImage}
                      alt="Remove item"
                      onClick={() => removeItem(index)}
                      className="poForm_trashIcon"
                    />
                  </li>
                ))}
              </ul>
            </Card>
          </Col>
        </Row>
        <ModalFooter>
          <Button className="rounded-card" color="primary" onClick={handleCreatePO}>Create PO</Button>
          <Button className="rounded-card" color="secondary" onClick={closeModal}>Close</Button>
        </ModalFooter>
      </Card>
    </div>
  );

};
const MyPOGrid = ({ headers, removeRow }) => {
  const [poList, setPoList] = useState([]); // State to store fetched data
  const [showModal, setShowModal] = useState(false);
  const [selectedPO, setSelectedPO] = useState(null);
  const [modal, setModal] = useState(false);


  const openModal = () => {
    setShowModal(true);
  };

  const handlePONumberClick = (poNumber) => {
    setSelectedPO(poNumber);
    openModal();
    console.log(`PO Number ${poNumber} clicked`);
  };

  const toggle = () => setShowModal(!showModal);

  useEffect(() => {
    const fetchPOList2 = async () => {
      const poCollectionRef = collection(db, "Inventory", "Pending", "Pending List");
      const querySnapshot = await getDocs(poCollectionRef);

      let dataList = querySnapshot.docs
        .filter(doc => doc.data().completed === true) // Only include documents where completed is true
        .map(doc => ({
          ...doc.data(),
          id: doc.id
        }));

      // Parse and sort the data by orderDate
      dataList.sort((a, b) => {
        const dateA = new Date(a.orderDate);
        const dateB = new Date(b.orderDate);
        return dateB - dateA; // For descending order
      });

      setPoList(dataList);
    };

    fetchPOList2();
  }, []);

  return (
    <div>
      <Modal isOpen={showModal} toggle={toggle} centered={true} style={{ width: '50%' }}>
        <Card className="rounded-card" style={{ backgroundColor: '#ffedf0' }}>
          {/* <ModalHeader toggle={toggle}>Your Modal Title</ModalHeader> */}
          {/* <ModalBody> */}
          <PurchaseOrderComponent givenMappedPoNumber={selectedPO} />
          {/* </ModalBody> */}
        </Card>
      </Modal>
      {/* <div className={`modal ${showModal ? 'open' : ''}`}>
        <div className="modal-content2">
          <span className="close5" onClick={() => setShowModal(false)}>&times;</span>
          <PurchaseOrderComponent givenMappedPoNumber={selectedPO} />
        </div>
      </div> */}

      <div className="table-responsive">
        <table className="table table-hover">
          <thead className="scrollable-thead">
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
              <th style={{ width: '50px' }}></th>
            </tr>
          </thead>
          <tbody className="scrollable-tbody">
            {poList.map((po, i) => (
              <tr key={po.id} className={i % 2 === 0 ? 'even-row' : 'odd-row'}>
                {Object.keys(FIELD_MAPPINGS).map((fieldKey, j) => {
                  return (
                    <td key={j}>
                      {j === 2 ?
                        <a href="#" onClick={() => handlePONumberClick(po[fieldKey])}>{po[fieldKey]}</a>
                        :
                        po[fieldKey]
                      }
                    </td>
                  );
                })}
                <td style={{ width: '50px' }}>
                  <img
                    src={trashImage}
                    alt="Trash Can"
                    style={{ width: '100%', height: 'auto' }}
                    onClick={() => removeRow(i)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};


export const ReceivePOForm = ({ selectedPO }) => {  // selectedPO is the PO that you click on
  const [items, setItems] = useState([]);

  // Simulate fetching data when selected PO changes
  useEffect(() => {
    if (selectedPO) {
      // Fetch your data here and set items
      setItems(selectedPO.items);  // This is just an example. Replace with actual logic.
    }
  }, [selectedPO]);

  const toggleReceived = (itemIndex, cellIndex) => {
    const newItems = [...items];
    newItems[itemIndex].expandedItems[cellIndex].received = !newItems[itemIndex].expandedItems[cellIndex].received;
    setItems(newItems);
  };

  return (
    <div className="form-wrapper" style={{ backgroundColor: '#ffe2e2' }}>
      <form className="input-container">
        <button type="button" className="close-button" onClick={() => {/* close logic here */ }}>X</button>

        <div className="item-list" style={{ backgroundColor: '#ffb6c1' }}>
          {items.map((item, itemIndex) => (
            <div key={itemIndex} className="item-row">
              <div style={{ backgroundColor: '#ffc1cc' }}>Supplier: {item.supplier}</div>
              <div style={{ backgroundColor: '#ffc1cc' }}>Product: {item.product}</div>
              <div style={{ backgroundColor: '#ffc1cc' }}>Quantity: {item.quantity}</div>
              <div>
                {Array.from({ length: item.quantity }).map((_, cellIndex) => (
                  <div key={cellIndex} style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      type="text"
                      placeholder="Lot"
                      onChange={e => {
                        const newItems = [...items];
                        newItems[itemIndex].expandedItems[cellIndex].lot = e.target.value;
                        setItems(newItems);
                      }}
                      style={{ backgroundColor: '#ffebf0' }}
                    />
                    <input
                      type="text"
                      placeholder="Serial Number"
                      onChange={e => {
                        const newItems = [...items];
                        newItems[itemIndex].expandedItems[cellIndex].serialNumber = e.target.value;
                        setItems(newItems);
                      }}
                      style={{ backgroundColor: '#ffebf0' }}
                    />
                    <input
                      type="date"
                      onChange={e => {
                        const newItems = [...items];
                        newItems[itemIndex].expandedItems[cellIndex].expiration = e.target.value;
                        setItems(newItems);
                      }}
                      style={{ backgroundColor: '#ffebf0' }}
                    />
                    <input
                      type="checkbox"
                      onChange={() => toggleReceived(itemIndex, cellIndex)}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <button type="submit" className="btn-save" style={{ backgroundColor: '#ff69b4', color: 'white' }}>Receive PO</button>
      </form>
    </div>
  );
};

const InvoiceStyle = () => (
  <style>
    {`
          .invoice-container {
              border: 1px solid #eee;
              padding: 20px;
              margin: 20px 0;
              box-shadow: 0 0 10px rgba(0,0,0,0.1);
              max-width: 1000px;
              margin-left: auto;
              margin-right: auto;
              display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
          }
          
          .invoice-header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              border-bottom: 1px solid #ddd;
              padding-bottom: 10px;
              margin-bottom: 20px;
          }

          .invoice-body .row {
              display: flex;
              justify-content: space-between;
              padding: 8px 0;
              border-bottom: 1px dashed #ddd;
          }

          .invoice-footer {
              margin-top: 20px;
              font-weight: bold;
          }

          .close-button {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
        }
      `}
  </style>
);




const PurchaseOrderComponent = ({ givenMappedPoNumber }) => {
  const [poNumbersList, setPoNumbersList] = useState([]);
  const [mappedPoNumbers, setMappedPoNumbers] = useState({});
  const [orderDetails, setOrderDetails] = useState(null);
  const [selectedPO, setSelectedPO] = useState('');

  useEffect(() => {
    const fetchPoNumbers = async () => {
      try {
        const response = await axios.get('https://imac-med-spa.labthree.ai/po-numbers');
        if (Array.isArray(response.data.poNumbers)) {
          setPoNumbersList(response.data.poNumbers);
        } else {
          console.warn("Expected array in poNumbers property but received:", response.data.poNumbers);
        }
      } catch (error) {
        console.error("Error fetching PO numbers:", error);
      }
    };

    fetchPoNumbers();
  }, []);

  useEffect(() => {
    const fetchMappedPoNumbers = async () => {
      const mapping = {};
      const promises = poNumbersList.map(async poNumber => {
        try {
          const response = await axios.get(`https://imac-med-spa.labthree.ai/consolidated-order-data/${poNumber}`);
          if (response.data && Array.isArray(response.data) && response.data[0].poNumber) {
            mapping[poNumber] = response.data[0].poNumber;
          }
        } catch (error) {
          console.error(`Error fetching consolidated data for PO number ${poNumber}:`, error);
        }
      });

      await Promise.all(promises);
      setMappedPoNumbers(mapping);
    };

    fetchMappedPoNumbers();
  }, [poNumbersList]);


  useEffect(() => {
    const fetchOrderDetails = async () => {
      console.log("Fetching order details for:", givenMappedPoNumber);  // Add this line
      const originalPoNumber = getOriginalPoNumber(givenMappedPoNumber);
      if (originalPoNumber !== "Original PO number not found") {
        try {
          const response = await axios.get(`https://imac-med-spa.labthree.ai/consolidated-order-data/${originalPoNumber}`);
          if (response.data && Array.isArray(response.data)) {
            console.log(response.data);
            setOrderDetails(response.data[0]);
          }
        } catch (error) {
          console.error(`Error fetching order details for original PO number ${originalPoNumber}:`, error);
        }
      }
    };

    fetchOrderDetails();
  }, [givenMappedPoNumber, mappedPoNumbers]);

  const getOriginalPoNumber = (givenMappedPoNumber) => {
    const originalPo = Object.keys(mappedPoNumbers).find(
      key => mappedPoNumbers[key] === givenMappedPoNumber
    );

    return originalPo || "Original PO number not found";
  };

  return (
    <div >
      {/* <InvoiceStyle /> */}

      {/* {orderDetails ? (
        <div className="invoice-container" style={{ backgroundColor: "rgba(255,182,193,0.85)", borderRadius: "30px" }}>
          <div className="invoice-header">
            <h3>Invoice</h3>
            <div>Order Date: {orderDetails.orderDate}</div>
          </div>
          <div className="invoice-body">
            <div className="row">
              <span>Original PO Number:</span>
              <span>{orderDetails.poNumber}</span>
            </div>
            <div className="row">
              <span>Vendor:</span>
              <span>{orderDetails.vendor}</span>
            </div>
            <div className="row">
              <span>Item Name:</span>
              <span>{orderDetails.itemName}</span>
            </div>
            <div className="row">
              <span>Item UUID:</span>
              <span>{orderDetails.itemUUID}</span>
            </div>
            <div className="row">
              <span>Cost Per Item:</span>
              <span>${orderDetails.costPerItem}</span>
            </div>
            <div className="row">
              <span>Total Purchased:</span>
              <span>{orderDetails.totalPurchased}</span>
            </div>
            <div className="row">
              <span>Quantity Received:</span>
              <span>{orderDetails.quantityReceived}</span>
            </div>
            <div className="row">
              <span>Expiration Dates:</span>
              <span>{orderDetails.expirationDates.join(', ')}</span>
            </div>
          </div>
          <div className="invoice-footer">
            Status: {orderDetails.completed ? "Completed" : "Pending"}
          </div>
        </div>
      ) : (
        <div>Loading order details...</div>
      )} */}
      {orderDetails ? (
        <div >
          <Table className="table" bordered striped >
            <tbody>
              <tr style={{ backgroundColor: '#ffeeea' }}>
                <th colSpan="2" className="text-center"><h3 className="bold-text text-black">Invoice</h3></th>
              </tr>
              <tr className="text-black">
                <td className='text-right font-weight-bold'>Order Date:</td>
                <td>{orderDetails.orderDate}</td>
              </tr>
              <tr className="text-black">
                <td className='text-right font-weight-bold'>Original PO Number:</td>
                <td>{orderDetails.poNumber}</td>
              </tr>
              <tr className="text-black">
                <td className='text-right font-weight-bold'>Vendor:</td>
                <td>{orderDetails.vendor}</td>
              </tr>
              <tr className="text-black">
                <td className='text-right font-weight-bold'>Item Name:</td>
                <td>{orderDetails.itemName}</td>
              </tr>
              <tr className="text-black">
                <td className='text-right font-weight-bold'>Item UUID:</td>
                <td>{orderDetails.itemUUID}</td>
              </tr>
              <tr className="text-black">
                <td className='text-right font-weight-bold'>Cost Per Item:</td>
                <td>${orderDetails.costPerItem}</td>
              </tr>
              <tr className="text-black">
                <td className='text-right font-weight-bold'>Total Purchased:</td>
                <td>{orderDetails.totalPurchased}</td>
              </tr>
              <tr className="text-black">
                <td className='text-right font-weight-bold'>Quantity Received:</td>
                <td>{orderDetails.quantityReceived}</td>
              </tr>
              <tr className="text-black">
                <td className='text-right font-weight-bold'>Expiration Dates:</td>
                <td>{orderDetails.expirationDates.join(', ')}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr style={{ backgroundColor: '#ffeeea' }} className="font-weight-bold text-black">
                <td colSpan="2" className="text-center">Status: {orderDetails.completed ? "Completed" : "Pending"}</td>
              </tr>
            </tfoot>
          </Table>
        </div>
      ) : (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center' // This centers the text horizontally if it wraps over multiple lines
        }}
          className="p-5 text-black"
        >
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <Oval
              height={50}
              width={50}
              color="#13CFEB"
              wrapperStyle={{ display: 'flex', justifyContent: 'center' }}
              wrapperClass="fastOval"
              visible={true}
              ariaLabel='oval-loading'
              secondaryColor="white"
              strokeWidth={3}
              strokeWidthSecondary={3}
            />
            <br />
            <span className="font-weight-bold">
              Loading order details...
            </span>
          </div>
        </div>
      )}

      {/* <hr /> */}

      {/* <h3>PO Numbers:</h3>
        {poNumbersList.map((poNumber, index) => (
            <div key={index}>
                Original: {poNumber}, Mapped: {mappedPoNumbers[poNumber]}
            </div>
        ))} */}
    </div>
  );

};










function Inventory() {
  const [activeTab, setActiveTab] = useState('1');
  const [rowCount, setRowCount] = useState(20);  // Initialize with 20 rows
  const [isCreatePOModalOpen, setCreatePOModalOpen] = useState(false);
  const [isReceivePOModalOpen, setReceivePOModalOpen] = useState(false);
  const [products, setProducts] = useState([]);

  const [addProductModal, setAddProductModal] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    vendor: "",
    cost: 0,
    price: 0,
    type: "",
    barcode: "",
    quantity: 0,
    minimum: 0
  });

  async function addProductWithModal() {
    // Update this to use form values instead of empty or 0.
    try {
      const newProduct = {
        Name: formValues.name,
        Vendor: formValues.vendor,
        Cost: formValues.cost,
        Price: formValues.price,
        Type: formValues.type,
        Barcode: formValues.barcode,
        Quantity: formValues.quantity,
        Minumum: formValues.minimum,
      };
      const docRef = await addDoc(collection(db, "Inventory", "Main Inventory", "Products"), newProduct);
      setProducts([...products, { id: docRef.id, ...newProduct }]);
      console.log("Document written with ID: ", docRef.id);
      fetchProductsRefresh();
      setAddProductModal(false); // Close the modal
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  const toggleAddProductModal = () => {
    setAddProductModal(!addProductModal);
  }


  const closeCreatePOModal = () => {
    setCreatePOModalOpen(false);
  }


  const searchProducts = (query) => {
    console.log("Searching products with query: ", query);
    // Implement your search functionality here for the Products tab
  };

  const searchCompletePO = (query) => {
    console.log("Searching complete PO with query: ", query);
    // Implement your search functionality here for the Complete Purchase Orders tab
  };

  const searchPendingPO = (query) => {
    console.log("Searching pending PO with query: ", query);
    // Implement your search functionality here for the Pending Purchase Orders tab
  };

  const searchLogs = (query) => {
    console.log("Searching logs with query: ", query);
    // Implement your search functionality here for the Logs tab
  };

  useEffect(() => {
    const fetchProducts = async () => {
      const productsCollection = collection(db, "Inventory", "Main Inventory", "Products"); // Make sure the path matches your Firestore structure
      const productsSnapshot = await getDocs(productsCollection);
      const productList = productsSnapshot.docs.map(doc => {
        return { id: doc.id, ...doc.data() };
      });
      setProducts(productList);
    };

    fetchProducts();
  }, []);

  const fetchProductsRefresh = async () => {
    const productsCollection = collection(db, "Inventory", "Main Inventory", "Products"); // Make sure the path matches your Firestore structure
    const productsSnapshot = await getDocs(productsCollection);
    const productList = productsSnapshot.docs.map(doc => {
      return { id: doc.id, ...doc.data() };
    });
    setProducts(productList);
  };


  const addRow = () => {
    setRowCount(prevRowCount => prevRowCount + 1);
  };

  const removeRow = (index) => {
    if (rowCount > 0) {
      setRowCount(prevRowCount => prevRowCount - 1);
    }
  };

  const openCreatePOModal = () => {
    setCreatePOModalOpen(true);
  }

  const openReceivePOModal = () => {
    setReceivePOModalOpen(true);
  };

  // async function addProduct() {
  //   try {
  //     const newProduct = {
  //       Name: "",
  //       Vendor: "",
  //       Cost: 0,
  //       Price: 0,
  //       Type: "",
  //       Barcode: "",
  //       Quantity: 0,
  //       InStock: true,
  //     };
  //     const docRef = await addDoc(collection(db, "Inventory", "Main Inventory", "Products"), newProduct);
  //     setProducts([...products, { id: docRef.id, ...newProduct }]);

  //     console.log("Document written with ID: ", docRef.id);
  //   } catch (e) {
  //     console.error("Error adding document: ", e);
  //   }
  // }

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="App">
      <Container className="text-left">
        <br />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={purpleBot} alt="Purple Bot" style={{ height: '10em', marginRight: '5px' }} />
          <div>
            <h2 className="bold-text text-black">Inventory Bot</h2>
            <p className=" text-black rounded-card" style={{ backgroundColor: '#fff' }}>
              I'm your AI-driven Inventory Bot, expert in streamlining inventory management. I help you minimize costs and maximize efficiency, keeping your business running smoothly!
            </p>
          </div>
        </div>
        {/* <br />
                <h3 className="bold-text text-black">Daily Report</h3>
                <br />
                <CardDeck>
                    <Card className="rounded-card  text-center">
                        <small className="">
                            Highest Selling Product
                        </small>
                        <h3>Aquaphor</h3>
                    </Card>
                    <Card className="rounded-card  text-center">
                        <small className="">
                           Items Sold
                        </small>
                        <h3>18</h3>
                    </Card>
                    <Card className="rounded-card  text-center">
                        <small className="">
                            Largest Item Count
                        </small>
                        <h3>Morpheus- 12</h3>
                    </Card>
                   
                    <Card className="rounded-card  text-center">
                        <small className="">
                            Smallest Item Count
                        </small>
                        <h3 className="">Botox- 2</h3>
                    </Card>
                </CardDeck>
                <br />
                <MyBarChart />
                <br /> */}
        <Nav style={{ display: 'flex', justifyContent: 'space-between' }}>
          <NavItem>
            <NavLink
              type="button"
              style={{ border: '1px solid grey', borderRadius: '5px' }}
              className={classnames({ 'active': activeTab === '1', 'font-weight-bold': activeTab === '1' })}
              onClick={() => { toggle('1'); }}
            >
              Products

            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              type="button"
              style={{ border: '1px solid grey', borderRadius: '5px' }}
              className={classnames({ 'active': activeTab === '2', 'font-weight-bold': activeTab === '2' })}
              onClick={() => { toggle('2'); }}
            >
              Pending Orders
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              type="button"
              style={{ border: '1px solid grey', borderRadius: '5px' }}
              className={classnames({ 'active': activeTab === '3', 'font-weight-bold': activeTab === '3' })}
              onClick={() => { toggle('3'); }}
            >
              Completed Orders
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              type="button"
              style={{ border: '1px solid grey', borderRadius: '5px' }}
              className={classnames({ 'active': activeTab === '4', 'font-weight-bold': activeTab === '4' })}
              onClick={() => { toggle('4'); }}
            >
              Logs
            </NavLink>
          </NavItem>
        </Nav>
        <hr />

        {/* Conditional rendering of the header */}
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {activeTab === '2' && <>
            <div>
              <h3 className='bold-text text-black'>Pending Purchase Orders</h3>
              <p>This is a list of all PO's that have not been received (fully), yet.</p>
            </div>
          </>
          }
          {activeTab === '3' && <>
            <h3 className='bold-text text-black'>Completed Purchase Orders</h3>
            <small></small>
          </>
          }
          {/* {activeTab === '4' && <>
            <h3 className='bold-text text-black'>Logs</h3>
            <small></small>
          </>
          } */}
          <div style={{ display: 'flex', alignItems: 'center' }}>



            {(['1'].includes(activeTab)) ? (

              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'right' }}>


                {(['1'].includes(activeTab)) ? (
                  <div className="ml-auto" >
                    <button
                      className="mx-1 rounded-card"
                      style={{ cursor: 'pointer', backgroundColor: 'white' }}
                      onClick={() => {
                        // addProduct();
                        // addRow();
                        toggleAddProductModal();
                      }}
                    >
                      Add new product
                    </button>
                  </div>
                ) : null}
              </div>
            ) : (
              activeTab === '2' && (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <button className="rounded-card mx-1" onClick={openCreatePOModal}>
                    Create
                  </button>

                  <button
                    className="rounded-card mx-1"
                    onClick={() => window.open('https://imac-staff.labthree.ai/receive-po', '_blank')}
                  >
                    Receive
                  </button>
                </div>
              )
            )}
          </div>
        </div>

        <Modal centered isOpen={addProductModal} toggle={toggleAddProductModal} size="lg">
          {/* <div className={`modal ${showModal ? 'open' : ''}`}> */}
          <div className="modal-content3">
            <Card body className="rounded-card" style={{ backgroundColor: '#FFF4EE' }}>
              <h3 className="text-left text-black bold-text">Add a new product</h3>
              <p>Fill out the fields below to add a new product to your inventory.</p>
              <Container>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label className="font-weight-bold text-black" for="name">Name</Label>
                      <Input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Product name"
                        value={formValues.name}
                        onChange={(e) => setFormValues({ ...formValues, name: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label className="font-weight-bold text-black" for="vendor">Vendor</Label>
                      <Input
                        type="text"
                        name="vendor"
                        id="vendor"
                        placeholder="Vendor"
                        value={formValues.vendor}
                        onChange={(e) => setFormValues({ ...formValues, vendor: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label className="font-weight-bold text-black" for="cost">Cost</Label>
                      <Input
                        type="number"
                        name="cost"
                        id="cost"
                        placeholder="Cost"
                        value={formValues.cost}
                        onChange={(e) => setFormValues({ ...formValues, cost: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label className="font-weight-bold text-black" for="price">Price</Label>
                      <Input
                        type="number"
                        name="price"
                        id="price"
                        placeholder="Price"
                        value={formValues.price}
                        onChange={(e) => setFormValues({ ...formValues, price: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label className="font-weight-bold text-black" for="type">Type</Label>
                      <Input
                        type="text"
                        name="type"
                        id="type"
                        placeholder="Type"
                        value={formValues.type}
                        onChange={(e) => setFormValues({ ...formValues, type: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label className="font-weight-bold text-black" for="barcode">Barcode</Label>
                      <Input
                        type="text"
                        name="barcode"
                        id="barcode"
                        placeholder="Barcode"
                        value={formValues.barcode}
                        onChange={(e) => setFormValues({ ...formValues, barcode: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Label className="font-weight-bold text-black" for="quantity">Quantity</Label>
                  <Input
                    type="number"
                    name="quantity"
                    id="quantity"
                    placeholder="Quantity"
                    value={formValues.quantity}
                    onChange={(e) => setFormValues({ ...formValues, quantity: e.target.value })}
                  />
                </FormGroup>
                <Button className="bold-text" color="primary" onClick={addProductWithModal}>Submit</Button>
                <Button className="bold-text" color="secondary" onClick={toggleAddProductModal}>Cancel</Button>
              </Container>
            </Card>
            {/* </div> */}
          </div>
        </Modal>

        <Modal isOpen={isCreatePOModalOpen} size="lg" centered="true">
          <CreatePOForm closeModal={closeCreatePOModal} />
        </Modal>


        <Modal isOpen={isReceivePOModalOpen} className="config-card">
          <h2>Receive PO</h2>
          <ReceivePOForm />
          <button onClick={() => setReceivePOModalOpen(false)} className="btn-close">Close</button>
        </Modal>
        <br />
        {activeTab === '1' && <MyCGrid headers={['Name', 'Vendor', 'Cost', 'Price', 'Type', 'Barcode', 'Quantity', 'Minimum']} rowCount={rowCount} products={products} removeRow={removeRow} />}
        {activeTab === '3' && <MyPOGrid headers={['Order Date', 'Vendor', 'PO Number', 'Qty Ordered', 'Total Spent($)']} rowCount={rowCount} removeRow={removeRow} />}
        {activeTab === '2' && <MyOGrid headers={['Order Date', 'Vendor', 'PO Number', 'Total Quantity', 'Total Cost']} removeRow={removeRow} />}
        {activeTab === '4' && <MyLGrid headers={['Item', 'Box', 'Units']} rowCount={rowCount} removeRow={removeRow} />}
        <br />


      </Container>
      <h3 className="bold-text text-black">Jobs</h3>
      <br />
      <CardDeck>
        <SimpleCard
          running="Running daily @ 8am"
          title="Product Expiry Alert"
          description="Every morning, I'll scan the inventory and email staff a list of products expiring today and within the next 7 days."
        />

        <SimpleCard
          running="Running daily @ 9pm"
          title="Minimum Stock Alert: Square Transactions"
          description="After all of today's Square transactions, I'll assess the stock and email you any products that hit the minimum quantity threshold with your daily emails tomorrow morning."
        />
      </CardDeck>
      <br />
      <CardDeck>
        <SimpleCard
          running="Running daily @ 8am"
          title="Vendor Delivery Alert"
          description="I'll monitor deliveries and notify you via email when a vendor fails to send the exact ordered quantity."
        />

        <SimpleCard
          running="Running weekly on Thursdays"
          title="Neurotoxin Stock Alert"
          description="Constantly monitoring neurotoxins' stock levels. If any drop below the unit minimum, I'll shoot an email alert immediately."
        />
      </CardDeck>


      <br />
      <br />
    </div>

  );
}

export default Inventory;
