import React, { useState, useEffect } from 'react';
import { Table, Container, Row, Col, Card, CardDeck, CardBody, Nav, NavItem, NavLink, Navbar, Input, NavbarBrand, Button } from 'reactstrap';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import ReactIosSwitch from 'react-ios-switch';
import '../App.css';
import blueBot from '../images/blue-bot.png'
import { FaPencilAlt } from 'react-icons/fa';
import { db } from '../firebase'; // Path to your firebase.js file
import { collection, onSnapshot, getDocs, query, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import axios from 'axios';
import { InfinitySpin } from "react-loader-spinner";
import ShiftScheduler from './tools/ShiftScheduler';
import { Oval } from 'react-loader-spinner'



function SpeechBubble({ defaultValue, label }) {
    const [textValue, setTextValue] = useState(defaultValue);
    const [isEditing, setIsEditing] = useState(false);

    return (
        <>
            <Card className="rounded-card">
                <small className="bold-text">{label}:</small> <br />
                {isEditing ? (
                    <>
                        <textarea
                            value={textValue}
                            onChange={(e) => setTextValue(e.target.value)}
                            // onBlur={() => setIsEditing(false)}
                            rows={4}
                            cols={20}
                            className="med-text rounded-card no-border"
                        />
                        <br />
                        <div className="text-center">
                            <Button color="primary" className="custom-button" onClick={() => setIsEditing(false)}>Save</Button>
                            <Button color="secondary" className="custom-button" onClick={() => setIsEditing(false)}>Cancel</Button>
                        </div>
                    </>
                ) : (
                    <div onClick={() => setIsEditing(true)}>
                        <span className="med-text">{textValue}</span>
                        <FaPencilAlt className="pencil-icon" />
                    </div>
                )}
            </Card>
        </>
    );
}
function UnconfirmedAppointments() {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false)
    const [element, setElement] = useState(<></>)


    const sendTestEmail = () => {
        setLoading(true);
        axios.get(`https://imac-med-spa.labthree.ai/job-10?email=${email}`)
            .then(response => {
                setLoading(false);

            })
            .catch(error => {
                console.error("Error sending test email: ", error);
                setLoading(false);
            });
    };

    return (
        <>
            <div className="text-center">
                {
                    loading ?
                        <>
                            <InfinitySpin
                                type="ThreeDots"
                                color="#00BFFF"
                                height={100}
                                width={100}
                            />
                            <p>Sending test email...</p>
                        </> :
                        <>
                            <input
                                className="rounded-card"
                                type="email"
                                placeholder="Enter email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <br /><br />
                            <button className="rounded-card" onClick={sendTestEmail}>Send Test Email</button>
                        </>
                }

            </div>
        </>
    );
}

function SimpleCard({ id, running, title, description, children }) {
    const [expanded, setExpanded] = useState(false); // State to toggle the description
    const [jobActive, setJobActive] = useState(true);
    const jobRef = doc(db, 'Jobs', 'Receptionist');

    useEffect(() => {
        const fetchData = async () => {
            const docSnap = await getDoc(jobRef);
            if (docSnap.exists()) {
                setJobActive(docSnap.data()[`job_${id}`]);
            }
        };
        fetchData();
    }, []);


    const toggleJob = async () => {
        await updateDoc(jobRef, { [`job_${id}`]: !jobActive });
        setJobActive(!jobActive);
    };


    return (
        <>
            <Card body className="rounded-card">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        <div style={{ left: '10px', display: 'flex', alignItems: 'center' }}>
                            {
                                jobActive ?
                                    <>
                                        <div style={{ width: '10px', height: '10px', backgroundColor: '#90EE90', borderRadius: '50%', animation: 'pulsate 2s infinite' }}></div>
                                        <span style={{ fontSize: '15px', marginLeft: '5px' }}>{running}</span>
                                    </> :
                                    <>
                                        <div style={{ width: '10px', height: '10px', backgroundColor: 'grey', borderRadius: '50%', animation: 'pulsate 2s infinite' }}></div>
                                        <span style={{ fontSize: '15px', marginLeft: '5px' }}>Inactive</span>
                                    </>
                            }
                        </div>
                        <h5 type="button" onClick={() => { setExpanded(!expanded) }} className="bold-text text-black">{title}</h5>
                        <p className="">{description}</p>
                    </div>
                    {expanded &&
                        <div className="pt-2 pr-2" style={{ position: 'absolute', top: '0', right: '0' }}>
                            <ReactIosSwitch
                                checked={jobActive}
                                onChange={toggleJob}
                            />
                        </div>
                    }
                </div>
                <div>
                    {expanded && children}
                </div>
            </Card >
        </>
    )
}

function Reception() {

    // https://trello.com/c/8W1rEypI
    function OneDayAppointmentReminder() {
        const [expanded, setExpanded] = useState(false);
        const [showConfirmation, setShowConfirmation] = useState(false);
        const [showCancellation, setShowCancellation] = useState(false);
        const [jobActive, setJobActive] = useState(null);
        const jobRef = doc(db, 'Jobs', 'Receptionist');

        useEffect(() => {
            const fetchData = async () => {
                const docSnap = await getDoc(jobRef);
                if (docSnap.exists()) {
                    setJobActive(docSnap.data().job_16);
                }
            };
            fetchData();
        }, []);

        const toggleJob = async () => {
            await updateDoc(jobRef, { 'job_16': !jobActive });
            setJobActive(!jobActive);
        };

        return (
            <>
                <Card body className="rounded-card">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                            <div style={{ left: '10px', display: 'flex', alignItems: 'center' }}>
                                {
                                    jobActive ?
                                        <>
                                            <div style={{ width: '10px', height: '10px', backgroundColor: '#90EE90', borderRadius: '50%', animation: 'pulsate 2s infinite' }}></div>
                                            <span style={{ fontSize: '15px', marginLeft: '5px' }}>Running daily @ 12pm</span>
                                        </> :
                                        <>
                                            <div style={{ width: '10px', height: '10px', backgroundColor: 'grey', borderRadius: '50%', animation: 'pulsate 2s infinite' }}></div>
                                            <span style={{ fontSize: '15px', marginLeft: '5px' }}>Inactive</span>
                                        </>
                                }
                            </div>
                            <h5 type="button" onClick={() => { setExpanded(!expanded) }} className="bold-text text-black">Appointment Reminder: 1 Days</h5>
                            <p className="">I will review tomorrow's appointments that are 'Not Confirmed' and send a reminder SMS + Email.</p>
                            <p className="">Based on how patients respond, I'll update appointments in Dr Chrono.</p>
                        </div>
                        {expanded &&
                            <div className="pt-2 pr-2" style={{ position: 'absolute', top: '0', right: '0' }}>
                                <ReactIosSwitch
                                    checked={jobActive}
                                    onChange={toggleJob}
                                />
                            </div>
                        }
                    </div>
                    {
                        expanded &&
                        <>

                            {/* <span className='text-primary med-text' type="button" onClick={() => { setShowConfirmation(!showConfirmation) }}>View confirmation text</span>
                            {
                                showConfirmation &&
                                <>
                                    <SpeechBubble defaultValue={`Hey there! Just a reminder that you have an appointment scheduled for {appointment_time} tomorrow. \n\n Please reply with '1' to confirm your appointment. \n Please reply with '2' to cancel and reschedule. \n If your appointment is not confirmed, you may be subject to a $50 cancellation fee.`} label="Reminder text" />
                                    <br />
                                </>
                            }
                            <span type="button" onClick={() => { setShowCancellation(!showCancellation) }} className='text-primary med-text'>View cancellation response</span>
                            {
                                showCancellation &&
                                <>
                                    <SpeechBubble defaultValue={`Thank you for letting us know! Your appointment has been cancelled. \n\n You can conveniently reschedule your appointment online by visiting https://www.imacmedspa.com/booking-page/!`} label="Reschedule text" />
                                    <br />
                                </>
                            } */}
                            <br />
                        </>
                    }


                </Card >
            </>
        )
    }

    // https://trello.com/c/nJKIK9YT
    function TwoDayAppointmentReminder() {
        const [expanded, setExpanded] = useState(false);
        const [showConfirmation, setShowConfirmation] = useState(false);
        const [showCancellation, setShowCancellation] = useState(false);
        const [jobActive, setJobActive] = useState(null);
        const jobRef = doc(db, 'Jobs', 'Receptionist');

        useEffect(() => {
            const fetchData = async () => {
                const docSnap = await getDoc(jobRef);
                if (docSnap.exists()) {
                    setJobActive(docSnap.data().job_56);
                }
            };
            fetchData();
        }, []);

        const toggleJob = async () => {
            await updateDoc(jobRef, { 'job_56': !jobActive });
            setJobActive(!jobActive);
        };

        return (
            <>
                <Card body className="rounded-card">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                            <div style={{ left: '10px', display: 'flex', alignItems: 'center' }}>
                                {
                                    jobActive ?
                                        <>
                                            <div style={{ width: '10px', height: '10px', backgroundColor: '#90EE90', borderRadius: '50%', animation: 'pulsate 2s infinite' }}></div>
                                            <span style={{ fontSize: '15px', marginLeft: '5px' }}>Running daily @ 12pm</span>
                                        </> :
                                        <>
                                            <div style={{ width: '10px', height: '10px', backgroundColor: 'grey', borderRadius: '50%', animation: 'pulsate 2s infinite' }}></div>
                                            <span style={{ fontSize: '15px', marginLeft: '5px' }}>Inactive</span>
                                        </>
                                }
                            </div>
                            <h5 type="button" onClick={() => { setExpanded(!expanded) }} className="bold-text text-black">Appointment Reminder: 2 Days</h5>
                            <p className="">I will review day after tomorrow's appointments that are 'Not Confirmed' and send a reminder SMS + Email.</p>
                            <p className="">Based on how patients respond, I'll update appointments in Dr Chrono.</p>
                        </div>
                        {expanded &&
                            <div className="pt-2 pr-2" style={{ position: 'absolute', top: '0', right: '0' }}>
                                <ReactIosSwitch
                                    checked={jobActive}
                                    onChange={toggleJob}
                                />
                            </div>
                        }
                    </div>
                    {
                        expanded &&
                        <>

                            {/* <span className='text-primary med-text' type="button" onClick={() => { setShowConfirmation(!showConfirmation) }}>View confirmation text</span>
                            {
                                showConfirmation &&
                                <>
                                    <SpeechBubble defaultValue={`Hey there! Just a reminder that you have an appointment scheduled for {appointment_time} tomorrow. \n\n Please reply with '1' to confirm your appointment. \n Please reply with '2' to cancel and reschedule. \n If your appointment is not confirmed, you may be subject to a $50 cancellation fee.`} label="Reminder text" />
                                    <br />
                                </>
                            }
                            <span type="button" onClick={() => { setShowCancellation(!showCancellation) }} className='text-primary med-text'>View cancellation response</span>
                            {
                                showCancellation &&
                                <>
                                    <SpeechBubble defaultValue={`Thank you for letting us know! Your appointment has been cancelled. \n\n You can conveniently reschedule your appointment online by visiting https://www.imacmedspa.com/booking-page/!`} label="Reschedule text" />
                                    <br />
                                </>
                            } */}
                            {/* {
                             jobActive ?
                              <span type="button" onClick={toggleJob} className='text-danger med-text'>Turn off bot</span> : 
                              <span type="button" onClick={toggleJob} className='text-success med-text'>Turn on bot</span>
                             } */}


                            <br />
                        </>
                    }


                </Card >
            </>
        )
    }

    // https://trello.com/c/5yGjo8zd
    function OneWeekAppointmentReminder() {
        const [expanded, setExpanded] = useState(false);
        const [showConfirmation, setShowConfirmation] = useState(false);
        const [showCancellation, setShowCancellation] = useState(false);
        const [jobActive, setJobActive] = useState(null);
        const jobRef = doc(db, 'Jobs', 'Receptionist');

        useEffect(() => {
            const fetchData = async () => {
                const docSnap = await getDoc(jobRef);
                if (docSnap.exists()) {
                    setJobActive(docSnap.data().job_73);
                }
            };
            fetchData();
        }, []);

        const toggleJob = async () => {
            await updateDoc(jobRef, { 'job_73': !jobActive });
            setJobActive(!jobActive);
        };

        return (
            <>
                <Card body className="rounded-card">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                            <div style={{ left: '10px', display: 'flex', alignItems: 'center' }}>
                                {
                                    jobActive ?
                                        <>
                                            <div style={{ width: '10px', height: '10px', backgroundColor: '#90EE90', borderRadius: '50%', animation: 'pulsate 2s infinite' }}></div>
                                            <span style={{ fontSize: '15px', marginLeft: '5px' }}>Running daily @ 12pm</span>
                                        </> :
                                        <>
                                            <div style={{ width: '10px', height: '10px', backgroundColor: 'grey', borderRadius: '50%', animation: 'pulsate 2s infinite' }}></div>
                                            <span style={{ fontSize: '15px', marginLeft: '5px' }}>Inactive</span>
                                        </>
                                }
                            </div>
                            <h5 type="button" onClick={() => { setExpanded(!expanded) }} className="bold-text text-black">Appointment Reminder: 1 Week</h5>
                            <p className="">I will review appointments 1 week from today and send a reminder SMS.</p>
                            {/* <p className="">Based on how patients respond, I'll update appointments in Dr Chrono.</p> */}
                        </div>
                        {expanded &&
                            <div className="pt-2 pr-2" style={{ position: 'absolute', top: '0', right: '0' }}>
                                <ReactIosSwitch
                                    checked={jobActive}
                                    onChange={toggleJob}
                                />
                            </div>
                        }
                    </div>
                    {
                        expanded &&
                        <>

                            {/* <span className='text-primary med-text' type="button" onClick={() => { setShowConfirmation(!showConfirmation) }}>View confirmation text</span>
                            {
                                showConfirmation &&
                                <>
                                    <SpeechBubble defaultValue={`Hey there! Just a reminder that you have an appointment scheduled for {appointment_time} tomorrow. \n\n Please reply with '1' to confirm your appointment. \n Please reply with '2' to cancel and reschedule. \n If your appointment is not confirmed, you may be subject to a $50 cancellation fee.`} label="Reminder text" />
                                    <br />
                                </>
                            }
                            <span type="button" onClick={() => { setShowCancellation(!showCancellation) }} className='text-primary med-text'>View cancellation response</span>
                            {
                                showCancellation &&
                                <>
                                    <SpeechBubble defaultValue={`Thank you for letting us know! Your appointment has been cancelled. \n\n You can conveniently reschedule your appointment online by visiting https://www.imacmedspa.com/booking-page/!`} label="Reschedule text" />
                                    <br />
                                </>
                            } */}
                            {/* {
                             jobActive ?
                              <span type="button" onClick={toggleJob} className='text-danger med-text'>Turn off bot</span> : 
                              <span type="button" onClick={toggleJob} className='text-success med-text'>Turn on bot</span>
                             } */}


                            <br />
                        </>
                    }


                </Card >
            </>
        )
    }

    function DisplayDetails() {
        const [callsSaved, setCallsSaved] = useState(0);
        const [emailSavings, setEmailSavings] = useState(0);
        const [loading, setLoading] = useState(false)
        useEffect(() => {
            setLoading(true);
            axios.get('https://imac-med-spa.labthree.ai/get-saved-calls')
                .then(response => {
                    setCallsSaved(response.data.totalSMS);
                    setEmailSavings(response.data.totalEmail);
                })
                .catch(error => {
                    console.log('Error fetching data:', error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }, []);


        return (
            <>
                <h3 className="bold-text text-black">Reception Data</h3>
                {
                    loading ?
                        <>
                            <div className="text-center">
                                Loading...
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <span className="ml-auto mr-auto">
                                        <Oval
                                            height={80}
                                            width={80}
                                            color="#13CFEB"
                                            wrapperStyle={{}}
                                            wrapperClass="fastOval"
                                            visible={true}
                                            ariaLabel='oval-loading'
                                            secondaryColor="#FFF4EE"
                                            strokeWidth={2}
                                            strokeWidthSecondary={2}
                                        />

                                    </span>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <CardDeck>
                                <Card className="rounded-card  text-center">
                                    <small className="">
                                        Appointments Confirmed via SMS
                                    </small>
                                    <h3 className="bold-text text-black">{callsSaved}</h3>
                                </Card>
                                <Card className="rounded-card  text-center">
                                    <small className="">
                                        Appointments Confirmed via Email
                                    </small>
                                    <h3 className="bold-text text-black">{emailSavings}</h3>
                                </Card>
                                <Card className="rounded-card  text-center">
                                    <small className="">
                                        Call Hours Saved
                                    </small>
                                    <h3 className="bold-text text-black">{Number((((callsSaved + emailSavings) * 75) / 60) / 60).toFixed(1)}</h3>
                                </Card>
                                {/* <Card className="rounded-card  text-center">
                                    <small className="">
                                        Reminders Sent
                                    </small>
                                    <h3 className="bold-text text-black">3</h3>
                                </Card>
                                <Card className="rounded-card  text-center">
                                    <small className="">
                                        Appointments Checked-In
                                    </small>
                                    <h3 className="bold-text text-black">5</h3>
                                </Card> */}
                            </CardDeck>
                        </>
                }

            </>
        )
    }

    return (
        <div className="App">
            <Container className="text-left">
                <br />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={blueBot} alt="Blue Bot" style={{ height: '9.8em', marginRight: '5px' }} />
                    <div>
                        <h2 className="bold-text text-black">Receptionist Bot</h2>
                        <p className=" text-black rounded-card" style={{ backgroundColor: '#fff' }}>Daily tasks like reminders, updating schedules, handling cancellations, confirming appointments, and more!</p>
                    </div>

                </div>
            </Container>
            <div style={{marginLeft:'-5%'}}>
                <br />
                <h3 className="bold-text text-black text-left">Shift Scheduler</h3>
                <ShiftScheduler />
                <br />
            </div>
            <Container className="text-left">
                <DisplayDetails />
                <br />
                <h3 className="bold-text text-black">Jobs</h3>
                <CardDeck>
                    <OneWeekAppointmentReminder />
                    <TwoDayAppointmentReminder />
                    <OneDayAppointmentReminder />

                </CardDeck>
                <br />
                <CardDeck>

                    <SimpleCard id="10" running="Running daily @ 8am" title="Email a list of unconfirmed appointments to staff" description="Every morning, I'll check to see if there are still appointments that are 'Not Confirmed' and email a list to staff for today and tomorrow.">
                        <UnconfirmedAppointments />
                    </SimpleCard>
                    <SimpleCard id="12" running="Running 24/7" title="Send happy customers a thank you message requesting a Google Review" description="Just type 'review' in the Notes of an appointment and update the status to 'Complete'! I'll send an SMS to the patient thanking them and asking them for a Google Review." />
                </CardDeck>
                <br />
                <CardDeck>
                    <SimpleCard id="18" running="Running daily @ 1pm" title="Send pre-op instructions to everyone" description="Pre-op instructions will be sent 2 day early to all patients." />
                    <SimpleCard id="70" running="Running daily @ 11am" title="Send follow-up appointment reminders" description="For procedures like Botox, Morpheus8, LHR, Fillers, Hydrofacial, Teeth Whitening, Microneedling - send follow up reminders.">
                        <table style={{ width: '100%' }}>
                            <thead style={{ border: '2px solid black' }}>
                                <tr>
                                    <td className=" font-weight-bold">Procedure</td>
                                    <td>Follow-up Time</td>
                                </tr>
                            </thead>
                            <tbody style={{ color: 'gray', fontStyle: 'italic' }}>
                                <tr>
                                    <td>Botox</td>
                                    <td>3 months</td>
                                </tr>
                                <tr>
                                    <td>Morpheus8</td>
                                    <td>6 months</td>
                                </tr>
                                <tr>
                                    <td>Laser</td>
                                    <td>6 months</td>
                                </tr>
                                <tr>
                                    <td>Any fillers</td>
                                    <td>6 months</td>
                                </tr>
                                <tr>
                                    <td>Hyrofacials</td>
                                    <td>30 days</td>
                                </tr>
                                <tr>
                                    <td>Teeth Whitening</td>
                                    <td>30 days</td>
                                </tr>
                                <tr>
                                    <td>Microneedling</td>
                                    <td>3 months</td>
                                </tr>
                            </tbody>
                        </table>
                    </SimpleCard>
                    {/* <SimpleCard id="12" running="Under development" title="Update patient profiles with their current promotions" description="I'll track and make sure patients in Dr Chrono have up-to-date promotion packages." /> */}
                </CardDeck>
                <br />
            </Container>
        </div>

    );
}

export default Reception;
