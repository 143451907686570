import React, { useState, useEffect, useRef } from 'react';
import { Container, Table, Row, Col, FormGroup, Label, Card, Modal, CardDeck, CardBody, Nav, NavItem, NavLink, Navbar, Input, NavbarBrand, Button } from 'reactstrap';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'; // import these at the top of your file
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { BsFillGearFill } from 'react-icons/bs';
import ReactIosSwitch from 'react-ios-switch';
import '../../App.css';
import { FaPencilAlt } from 'react-icons/fa';
import { IoAddCircle } from 'react-icons/io5';
import axios from 'axios';
import { db } from '../../firebase'; // Path to your firebase.js file
import { collection, onSnapshot, getDocs, getDoc, query, doc, setDoc, updateDoc } from 'firebase/firestore';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { InfinitySpin } from "react-loader-spinner";
import moment from 'moment';


const renderers = {
    h1: ({ children }) => <h1 style={{ fontSize: '30px', color: 'black', fontFamily: 'SF Bold' }}>{children}</h1>,
    h2: ({ children }) => <h2 style={{ fontSize: '25px', color: 'black', fontFamily: 'SF Regular' }}>{children}</h2>,
    h3: ({ children }) => <h3 style={{ fontSize: '20px', color: 'black', fontFamily: 'SF Medium' }}>{children}</h3>,
    p: ({ children }) => <p style={{ fontSize: '16px', color: 'black', fontFamily: "SF Regular" }}>{children}</p>,
    // Add other elements as needed
};


const BlogWriter = () => {
    const [expanded, setExpanded] = useState(false);
    const [textValue, setTextValue] = useState(`You are an all-star sales person that specializes in Social Media management. You love replying to people in Instagram comments while creatively working on driving users to IMAC MedSpa for aesthetic procedures that can help them become the best versions of themselves!`);
    const [isEditing, setIsEditing] = useState(false);
    const [topicEditing, setTopicEditing] = useState(false);
    const [topicValue, setTopicValue] = useState('-');
    const [blogs, setBlogs] = useState([])
    const [topicLoading, setTopicLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState("");
    const [updater, setUpdater] = useState(false);
    const [instructions, setInstructions] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false); // Add this line to manage dropdown state

    const [jobActive, setJobActive] = useState(true);

    async function toggleJob() {
        console.log('test')
    }

    const toggle = () => setDropdownOpen(prevState => !prevState); // Function to toggle dropdown
    const MarkdownModal = ({ show, markdown, toggle }) => (
        <>
            <Modal size="lg" isOpen={show} toggle={toggle}>
                <Card>
                    <CardBody>
                        <ReactMarkdown rehypePlugins={[rehypeRaw]} components={renderers}>{markdown}</ReactMarkdown>
                    </CardBody>
                    <Button color="secondary" onClick={toggle}>Close</Button>
                </Card>
            </Modal>
        </>
    );
    // useEffect(() => {
    //     const fetchData = async () => {
    //         const bloggerCollection = collection(db, 'Blogger');
    //         const bloggerSnapshot = await getDocs(bloggerCollection);
    //         const bloggerList = bloggerSnapshot.docs.map(doc => doc.data());
    //         const instructionsList = [];

    //         for (let i = 0; i < bloggerList.length; i++) {
    //             if (bloggerList[i].title === "Instruction") {
    //                 setTextValue(bloggerList[i].text);
    //             }
    //             if (bloggerList[i].title !== "Instruction") {
    //                 instructionsList.push(bloggerList[i]);
    //             }
    //         }
    //         console.log(bloggerList)
    //         setBlogs(instructionsList);
    //     };

    //     fetchData();
    // }, [updater]);

    async function generateBlogAndDisplay() {
        setTopicLoading(true)
        try {
            const response = await axios({
                method: 'post',
                url: 'https://hook.us1.make.com/zwj9278kkm5ul6h1fntyk8ulrz95txsa',
                data: {
                    "idea": topicValue
                },
                timeout: 60000 // 1 minute timeout
            });

            if (response.status !== 200) {
                throw new Error('Not 200 status');
            }
        } catch (error) {
            console.log(error)
        }
        setTopicLoading(false)
        setUpdater(!updater)
    }

    const toggleModal = content => {
        setModalContent(content);
        setShowModal(!showModal);
    };


    return (
        <>
            <Card body className="rounded-card">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        <div style={{ left: '10px', display: 'flex', alignItems: 'center' }}>
                            {
                                jobActive ?
                                    <>
                                        <div style={{ width: '10px', height: '10px', backgroundColor: '#90EE90', borderRadius: '50%', animation: 'pulsate 2s infinite' }}></div>
                                        <span style={{ fontSize: '15px', marginLeft: '5px' }}>Weekly on Wednesdays</span>
                                    </> :
                                    <>
                                        <div style={{ width: '10px', height: '10px', backgroundColor: 'grey', borderRadius: '50%', animation: 'pulsate 2s infinite' }}></div>
                                        <span style={{ fontSize: '15px', marginLeft: '5px' }}>Inactive</span>
                                    </>
                            }
                        </div>
                        <h5 type="button" onClick={() => { setExpanded(!expanded) }} className="bold-text text-black">Writing blog content</h5>
                        <p className="">Add to a list of topics that I can choose from and write blogs about each week!</p>
                    </div>
                    {expanded &&
                        <div className="pt-2 pr-2" style={{ position: 'absolute', top: '0', right: '0' }}>
                            <ReactIosSwitch
                                checked={jobActive}
                                onChange={toggleJob}
                            />
                        </div>
                    }
                </div>
                {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                    <div style={{ left: '10px', display: 'flex', alignItems: 'center' }}>
                        <div style={{ width: '10px', height: '10px', backgroundColor: '#90EE90', borderRadius: '50%', animation: 'pulsate 2s infinite' }}></div>
                        <span style={{ fontSize: '15px', marginLeft: '5px' }}>Weekly on Wednesdays</span>
                    </div>
                </div>
                <h5 type="button" onClick={() => { setExpanded(!expanded) }} className="bold-text text-black">Writing blog content</h5>
                <p className="">I will find different topics related to IMAC MedSpa and write nice blog posts to keep customers engaged.</p> */}

                {
                    expanded &&
                    <>
                        <br />
                        <small>Add a blog topic!</small>
                        {topicLoading ? (
                            <div className="text-center">
                                <InfinitySpin
                                    type="ThreeDots"
                                    color="#00BFFF"
                                    height={100}
                                    width={100}
                                />
                                <p>Writing blog article...</p>
                            </div>
                        ) : (
                            <>
                                <Card className="no-border rounded-card speech-bubble-2">
                                    {topicEditing ? (
                                        <>
                                            <textarea
                                                value={topicValue}
                                                onChange={(e) => setTopicValue(e.target.value)}
                                                rows={1}
                                                cols={500}
                                                style={{ width: '100%' }}
                                                className="med-text rounded-card no-border"
                                            />
                                            <br />
                                            <div className="text-center">
                                                <Button color="primary" className="rounded-card med-text" onClick={() => { generateBlogAndDisplay(); setTopicEditing(false) }}>Add</Button>
                                                <Button className="rounded-card med-text" onClick={() => { setTopicEditing(false) }}>Cancel</Button>
                                            </div>
                                        </>
                                    ) : (
                                        <div onClick={() => setTopicEditing(true)}>
                                            <span className="speech-text med-text">{topicValue}</span>
                                            <FaPencilAlt className="pencil-icon" />
                                        </div>
                                    )}
                                </Card>
                            </>
                        )}

                        <br />
                        <br />
                        <div>
                            <Table className="font-italic">
                                <tr className="med-text text-black">
                                    Scheduled Blogs
                                </tr>
                                {blogs.map((blogs, index) => (
                                    <tr key={index} onClick={() => toggleModal(blogs.markdown)}>
                                        <td type="button" className="text-blue">{blogs.title}</td>
                                    </tr>
                                ))}
                            </Table>
                            <MarkdownModal show={showModal} markdown={modalContent} toggle={() => toggleModal("")} />
                        </div>
                        <br />
                        <small type="button" onClick={() => { setInstructions(!instructions) }} className='text-primary bold-text'>View Instructions</small>
                        {
                            instructions &&
                            <>
                                <br />
                                <Card body className="no-border rounded-card speech-bubble-2">
                                    {isEditing ? (
                                        <>
                                            <textarea
                                                value={textValue}
                                                onChange={(e) => setTextValue(e.target.value)}
                                                // onBlur={() => setIsEditing(false)}
                                                rows={4}
                                                cols={500}
                                                style={{ width: '100%' }}
                                                className="med-text rounded-card no-border"
                                            />
                                            <br />
                                            <Button className="rounded-card med-text" onClick={() => { setIsEditing(false) }}>Save</Button>
                                            <Button className="rounded-card med-text" onClick={() => { console.log(blogs) }}>Test</Button>
                                        </>
                                    ) : (
                                        <div onClick={() => setIsEditing(true)}>
                                            <span className="speech-text med-text">{textValue}</span>
                                            <FaPencilAlt className="pencil-icon" />
                                        </div>
                                    )}
                                </Card>

                            </>
                        }
                        <br />
                        <small type="button" className='text-danger bold-text'>Deactivate</small>

                    </>
                }
            </Card >
        </>
    )
}
export default BlogWriter;